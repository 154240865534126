import React from "react";

type Props = {};

export const ChevronRightIcon = (props: Props) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61062 2.89988C4.78097 2.75387 5.03744 2.7736 5.18346 2.94395L8.43346 6.73561C8.56386 6.88775 8.56386 7.11225 8.43346 7.26438L5.18346 11.056C5.03744 11.2264 4.78097 11.2461 4.61062 11.1001C4.44027 10.9541 4.42054 10.6976 4.56656 10.5273L7.58994 7L4.56656 3.47272C4.42054 3.30236 4.44027 3.0459 4.61062 2.89988Z"
        fill="#626161"
      />
    </svg>
  );
};
export const ChevronDownIcon = (props: Props) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6002 4.61062C10.7462 4.78097 10.7265 5.03743 10.5561 5.18345L6.76446 8.43345C6.61232 8.56385 6.38783 8.56385 6.23569 8.43345L2.44402 5.18345C2.27367 5.03743 2.25394 4.78097 2.39996 4.61062C2.54597 4.44026 2.80244 4.42054 2.97279 4.56655L6.50007 7.58994L10.0274 4.56655C10.1977 4.42054 10.4542 4.44026 10.6002 4.61062Z"
        fill="#626161"
      />
    </svg>
  );
};
