import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
// import { store } from "./app/store";
import { store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import { ProSidebarProvider } from "react-pro-sidebar";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ColorModeScript />
        <HelmetProvider>
          <ProSidebarProvider>
            <App />
          </ProSidebarProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

serviceWorker.unregister();
reportWebVitals();
