import React from "react";

export const ArrowRightIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 11.2501L19.6502 11.2501L19.6502 12.7501L3.75 12.7501L3.75 11.2501Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3896 18.0224L18.4374 11.9996L12.3896 5.97783L13.448 4.91489L20.5632 11.9995L13.4481 19.0852L12.3896 18.0224Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
export const ArrowRightIconWhite = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8169 7.85712L10.0182 13.1169H7.97276L11.187 8.73375H2.01172V6.9805H11.187L7.97276 2.59738H10.0182L13.8169 7.85712Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowLeftIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.25 11.2501L4.34976 11.2501L4.34976 12.7501L20.25 12.7501L20.25 11.2501Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6104 18.0224L5.5626 11.9996L11.6104 5.97783L10.552 4.91489L3.43679 11.9995L10.5519 19.0852L11.6104 18.0224Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export const ArrowLeftIconWhite = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8 12L8.3 21H11.8L6.3 13.5H22V10.5H6.3L11.8 3H8.3L1.8 12Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowLeftIconWhiteBold = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8 12L8.3 21H11.8L6.3 13.5H22V10.5H6.3L11.8 3H8.3L1.8 12Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowLeftMenu = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1422 7.99999L5.2672 13.7115H7.48835L3.99797 8.95191H13.9614V7.04807H3.99797L7.48835 2.28845H5.2672L1.1422 7.99999Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowLeftCheckout = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.25 11.25L4.34976 11.25L4.34976 12.75L20.25 12.75L20.25 11.25Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6094 18.0221L5.56162 11.9993L11.6094 5.97759L10.551 4.91465L3.43581 11.9993L10.5509 19.085L11.6094 18.0221Z"
        fill="black"
      />
    </svg>
  );
};

export const starIcon = (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.73043 0.151978L8.88578 3.82676L13.0712 4.7268L10.2179 7.89798L10.6492 12.129L6.73043 10.4141L2.81161 12.129L3.243 7.89798L0.389648 4.7268L4.57508 3.82676L6.73043 0.151978Z"
      fill="#FFCB45"
    />
  </svg>
);

export const BackIcon = (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 16L1.5 9L8.5 2"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export const StrokeBack = () => {
  return (
    <svg
      width="5"
      height="9"
      viewBox="0 0 5 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.610623 0.399872C0.780974 0.253857 1.03744 0.273585 1.18346 0.443936L4.43346 4.2356C4.56386 4.38774 4.56386 4.61223 4.43346 4.76437L1.18346 8.55604C1.03744 8.72639 0.780974 8.74612 0.610623 8.6001C0.440272 8.45409 0.420544 8.19762 0.566559 8.02727L3.58994 4.49999L0.566559 0.972704C0.420544 0.802353 0.440272 0.545888 0.610623 0.399872Z"
        fill="#626161"
      />
    </svg>
  );
};

export const DropDown = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6654 6.16667L7.9987 10.8333L3.33203 6.16667"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export const ArrowLeftIconBold = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8 12L8.3 21H11.8L6.3 13.5H22V10.5H6.3L11.8 3H8.3L1.8 12Z"
        fill="black"
      />
    </svg>
  );
};
