import * as React from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import BaseRoutes from "./Routes/BaseRoutes";
import { CombinedContextProvider } from "./Customer/context/CombinedContextProvider";
import { InitializeAuth } from "./redux/services/InitializeAuth";

export const App = () => {
  const toastOpt = {
    style: {
      borderRadius: "3px",
      background: "#ffffff",
      color: "#1D2939",
      fontSize: ".9rem",
    },
    success: {
      iconTheme: {
        secondary: "#1F845A",
        primary: "white",
      },
      style: {
        color: "#1D2939",
        fontSize: ".9rem",
        borderRadius: "3px",
        background: "#ffffff",
      },
    },
    error: {
      style: {
        color: "#1D2939",
        fontSize: ".9rem",
        borderRadius: "3px",
        background: "#ffffff",
      },
      iconTheme: {
        secondary: "#F97066",
        primary: "white",
      },
    },
  };

  return (
    <CombinedContextProvider>
      <ChakraProvider theme={theme}>
        <InitializeAuth />
        <Toaster
          containerStyle={{
            zIndex: "10000000",
          }}
          toastOptions={toastOpt}
        />
        <BaseRoutes />
      </ChakraProvider>
    </CombinedContextProvider>
  );
};
