import { Box, Flex, Text } from "@chakra-ui/react";
import styled from "styled-components";

export const SizeBtn = styled.button`
  width: fit-content;
  font-family: Geist;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.36px;
  text-align: center;
  color: #6f6f6f;
  cursor: pointer;
  letter-spacing: 0.2em;
  padding-bottom: 18px;

  &.active-link {
    color: #000000;
  }
`;

export const FilterBtn = styled.button`
  width: fit-content;
  font-family: Geist;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.36px;
  text-align: center;
  color: #6f6f6f;
  cursor: pointer;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: 610px) {
    padding-left: 0px;
    padding-right: 0px;
    flex: 1;
  }

  &.active-link {
    color: #000000;
  }
`;

export const SidebarFilterBtn = styled.button`
  font-family: Geist;
  font-size: 13px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.2em;
  text-align: center;
  color: #626161;
  padding-bottom: 18px;
  cursor: pointer;
  width: 50%;

  &.active-link {
    border-bottom: 2px solid black;
    transition: 0.3s ease-in-out;
    color: #000000;
  }
`;

export const H4 = styled.h4`
  font-family: Geist;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  width: 100%;
  letter-spacing: 0.5em;
`;

export const PageTitle = styled(Text)`
  font-family: Geist;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.8px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
`;

export const TechPageTitle = styled(Text)`
  font-family: Akira Expanded;
  font-size: 22.84px;
  font-weight: 800;
  line-height: 19.76px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #feea00;
  text-transform: uppercase;
`;

export const LookViewBackText = styled(Text)`
  font-family: Geist;
  font-size: 13px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
`;

export const ClothItemBox = styled(Box)`
  background: #f7f7f7;
  font-family: Geist;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.36px;
  text-align: center;
  padding: 11px 10px 11px 10px;
  display: flex;
  gap: 10px;
  span {
    color: #747474;
  }
`;

export const ToolContainer = styled(Flex)`
  gap: 18px;

  .tooltip-container {
    position: relative;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 17px;
    color: #ffffff;
    border-radius: 8px;
  }

  .tooltip {
    height: 34px;
    width: 91px;
    position: absolute;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.6em 1em;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s;
    border-radius: 8px;
    background: white;
    color: #344054;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

  .tooltip::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    border-style: solid;
    border-width: 8px 8px 0;
    border-color: white transparent transparent;
  }

  .tooltip-container:hover {
    color: white;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  }

  .tooltip-container:hover .tooltip {
    top: -220%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
`;

export const SubText = styled(Text)`
  font-family: Geist;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #8f8f8f;

  @media screen and (max-width: 610px) {
    font-size: 11px;
  }
`;

export const FilterBtns = styled(Text)`
  font-family: Geist;
  font-size: 11px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2em;
  text-align: left;
  color: black;
  text-transform: uppercase;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  .bigger {
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.2em;
    text-align: left;
  }
`;

export const ItemsFound = styled(Text)`
  font-family: Geist;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.88px;
  text-align: center;
  color: #ffffff;
`;

export const CheckoutTitle = styled.h1`
  font-family: Geist;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.8px;
  letter-spacing: 0.2em;
  text-align: left;
`;

export const CheckoutContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .checkout {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 30px 30px 30px 30px;
    /* overflow: auto; */
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 69vh;
      flex-direction: column;
      padding: 0px 20px 0px 20px;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .checkout::-webkit-scrollbar {
    display: none;
  }

  .title {
    font-family: Geist;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.8px;
    letter-spacing: 0.2em;
    text-align: left;
    text-transform: uppercase;
  }

  .delivery {
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.2em;
    text-align: left;
    text-transform: uppercase;
  }

  .texts {
    font-family: Geist;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.36px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  Input {
    border: 0.5px solid #e6e6e6;
    border-radius: 0px;
    font-family: Geist;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.36px;
    text-align: left;
    color: #626161;
  }

  .order-details {
    width: 45%;
    height: 100%;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media screen and (max-width: 768px) {
      width: 100%;
      /* height: calc(100vh - 58px); */
      padding: 0px 20px 20px 20px;
      flex-direction: column;
    }

    &.show-mobile {
      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  .order-details::-webkit-scrollbar {
    display: none;
  }

  .mobile-button-container {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 58px;
    padding: 0 20px;
    background-color: white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .card {
    background-color: #f7f7f7;
    border-radius: 0px;
    padding: 30px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const OutfitContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 6.05px 20.74px 0px #0000000d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }

  .wrapper {
    width: 65%;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-top: 0px;
    }
  }
  .profile-wrapper {
    width: 57%;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-top: 0px;
    }
  }
  .collab-wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding-top: 0px;
      padding-left: 10px;
      padding-right: 0px;
    }
  }

  .title {
    font-family: Geist;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.8px;
    letter-spacing: 0.2em;
    text-align: left;
  }
  .user-account {
    display: flex;
    gap: 8px;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0px 10px;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .image {
    width: 40px;
    height: 40px;
    background: #d9d9d9;
    border-radius: 100%;
  }

  Image {
    width: 39px;
    height: 39px;
  }

  .user-details {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .user-name {
    font-family: Geist;
    font-size: 14px;
    font-weight: 700;
    line-height: 21.06px;
    text-align: left;
  }

  .description {
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #8f8f8f;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (max-width: 456px) {
      margin-bottom: 0px;
    }
  }

  .modal-header {
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const PageSub = styled(Text)`
  font-family: Geist;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2em;
  text-align: left;
  text-transform: uppercase;
`;

export const NewPostContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }

  .wrapper {
    width: 80%;
  }

  .image-upload-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
  }

  .image-preview-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 456px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .image-preview {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    img {
      width: 100%;
      height: 270px;
      object-position: top;
      object-fit: cover;
    }

    button {
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba(0, 0, 0, 0.47);
      color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 4px;
      cursor: pointer;
    }
  }

  .upload-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #919191;
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 16.12px;
    text-align: center;
    padding: 12px;
    gap: 12px;
    /* grid-column: span 2; */
    /* grid-column: 1 / -1; */
    width: 100%;
    height: 100%;
  }

  .upload-container {
    width: 1005;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 456px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .upload {
    /* width: 177px; */
    height: 270px;
    top: 228px;
    left: 694px;
    gap: 0px;
    opacity: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #f7f7f7;
  }

  .footer {
    width: 100%;
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    box-shadow: 0px -6px 34px 0px #00000026;

    margin-top: 70px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 70px;
    }
  }
`;

export const AddProductsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-left: 0px;
    padding-top: 0px;
  }

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 768px) {
      padding-right: 0px;
      padding-top: 0px;
    }
  }

  .carousel {
  }

  .product {
    width: 180px;
    height: 317px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title {
    font-family: Geist;
    font-size: 13.8px;
    font-weight: 600;
    line-height: 17.12px;
    text-align: left;
  }

  .subText {
    font-family: Geist;
    font-size: 10.35px;
    font-weight: 500;
    line-height: 12.84px;
    text-align: left;
    color: #8f8f8f;
  }
`;

export const LookBookContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 70px 30px;
  box-shadow: 0px 6.05px 20.74px 0px #0000000d;

  @media screen and (max-width: 768px) {
    padding: 0px 0px 70px 0px;
  }

  .title {
    font-family: Geist;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.84px;
    letter-spacing: 0.2em;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 30px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      padding: 0px 20px 0px 20px;
      margin-top: 0px;
    }

    @media screen and (max-width: 456px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .look-title {
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .look-number {
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #858585;
  }
`;

export const DraftsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 70px 30px;
  box-shadow: 0px 6.05px 20.74px 0px #0000000d;

  @media screen and (max-width: 768px) {
    padding: 0px 0px 70px 20px;
  }

  .wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    @media screen and (max-width: 768px) {
      width: 100%;
      gap: 20px;
      padding-top: 0px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (max-width: 456px) {
      margin-bottom: 0px;
    }
  }

  .title {
    font-family: Geist;
    font-size: 13.8px;
    font-weight: 600;
    line-height: 17.12px;
    letter-spacing: 0.2em;
    text-align: left;
    color: black;
  }

  .subText {
    font-family: Geist;
    font-size: 10.35px;
    font-weight: 500;
    line-height: 12.84px;
    text-align: left;
    color: #8f8f8f;
  }
  .user-account {
    display: flex;
    gap: 8px;
    margin-top: 30px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .image {
    width: 49px;
    height: 47px;
    background: #d9d9d9;
    border-radius: 100%;
  }

  Image {
    width: 39px;
    height: 39px;
  }

  .user-details {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .user-name {
    font-family: Geist;
    font-size: 14px;
    font-weight: 700;
    line-height: 21.06px;
    text-align: left;
  }

  .description {
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #000000;
  }
`;

export const ShopByVendorsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 70px 30px;
  box-shadow: 0px 6.05px 20.74px 0px #0000000d;

  @media screen and (max-width: 768px) {
    padding: 0px 0px 70px 0px;
  }
`;

export const CategoryPageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 70px 0px;
  box-shadow: 0px 6.05px 20.74px 0px #0000000d;

  @media screen and (max-width: 768px) {
    padding: 0px 0px 70px 0px;
  }
`;

export const ProfilePageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 70px 0px;
  box-shadow: 0px 6.05px 20.74px 0px #0000000d;

  @media screen and (max-width: 768px) {
    padding: 0px 0px 70px 0px;
  }
`;
export const SubscriptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 70px 0px;
  box-shadow: 0px 6.05px 20.74px 0px #0000000d;

  @media screen and (max-width: 768px) {
    padding: 0px 0px 70px 0px;
  }
`;

export const GetWaitlistContainer = styled.div`
  height: 100%;
  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  nav {
    width: 100%;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 456px) {
      padding: 10px;
    }
  }
`;
