import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserStories } from "../Customer/pages/user/ProfilePage";
import { NotFoundPage } from "../Components/404NotFount";
import { userPosts } from "../Customer/utils/data";

// assets
import userProfileImg from "../assets/images/useProfileImg.png";
import profileBg from "../assets/images/profileImg.png";
import { Loaders } from "../Components/Loaders/Loaders";

// Lazy loading of components
const Marketing = lazy(() => import("../Pages/Marketing"));
const GetWaitlist = lazy(() => import("../Container/GetWaitlist"));
const VerifyEmail = lazy(() => import("../Pages/VerifyEmail"));
const AuthForm = lazy(
  () => import("../Customer/Components/Forms/auth/SwitchAuth")
);
const StyleMapping = lazy(
  () => import("../Vendor/pages/stylemapping/StyleMapping")
);
const StyleMapView = lazy(
  () => import("../Vendor/pages/stylemapping/StyleMapView")
);
const ForgotVendorPassword = lazy(
  () => import("../Vendor/pages/Protected/ForgotPassword")
);
const ResetVendorPassword = lazy(
  () => import("../Vendor/pages/Protected/ResetPassword")
);
const ForgotCustomerPassword = lazy(
  () => import("../Customer/pages/protected/ForgotPassword")
);
const ResetCustomerPassword = lazy(
  () => import("../Customer/pages/protected/ResetPassword")
);
const VendorPageLayout = lazy(() => import("../Vendor/Layout/PageLayout"));
const PageLayout = lazy(() => import("../Customer/Layouts/PageLayout"));
const VendorDashboard = lazy(() => import("../Vendor/pages/Dashboard"));
const VendorOrders = lazy(() => import("../Vendor/pages/Orders"));
const VendorOrderView = lazy(() => import("../Vendor/pages/OrderView"));
const VendorInventory = lazy(() => import("../Vendor/pages/Inventory"));
const VendorCustomers = lazy(() => import("../Vendor/pages/Customers"));
const VendorSettings = lazy(() => import("../Vendor/pages/Settings"));
const Step1 = lazy(() => import("../Vendor/pages/addProducts/Step1"));
const Step2 = lazy(() => import("../Vendor/pages/addProducts/Step2"));
const Categories = lazy(() => import("../Customer/pages/product/Categories"));
const ProfilePage = lazy(() => import("../Customer/pages/user/ProfilePage"));
const LandingPage = lazy(
  () => import("../Customer/pages/LandingPage/LandingPage")
);
const Stories = lazy(() => import("../Customer/Container/Stories"));
const LookBook = lazy(() => import("../Customer/pages/looks/LookBook"));
const UserLooks = lazy(() => import("../Customer/pages/looks/UserLooks"));
//  eslint-disable-next-line
const LookDetails = lazy(() => import("../Customer/pages/looks/LookDetails"));
const Outfits = lazy(() => import("../Customer/pages/outfits/Outfits"));
const NewPost = lazy(() => import("../Customer/pages/outfits/NewPost"));
const AddProduct = lazy(() => import("../Customer/pages/outfits/AddProduct"));
const Drafts = lazy(() => import("../Customer/pages/outfits/Drafts"));
const SavedLikedItems = lazy(() => import("../Customer/pages/SavedLikedItems"));
const Orders = lazy(() => import("../Customer/pages/Orders"));
const ShopByStyle = lazy(
  () => import("../Customer/pages/shopByStyle/ShopByStyle")
);
const Looks = lazy(() => import("../Customer/pages/shopByStyle/Looks"));
const LookDetailsCustomer = lazy(
  () => import("../Customer/pages/looks/LookDetails")
);
const BuyProduct = lazy(() => import("../Customer/pages/product/BuyProduct"));
const ShopByEvents = lazy(() => import("../Customer/pages/ShopByEvents"));
const ShopByVendors = lazy(
  () => import("../Customer/pages/shopByVendor/ShopByVendors")
);
const Vendors = lazy(() => import("../Customer/pages/shopByVendor/Vendors"));
const VendorDetails = lazy(
  () => import("../Customer/pages/shopByVendor/VendorDetails")
);
const ShopByProducts = lazy(() => import("../Customer/pages/ShopByProducts"));
const Explore = lazy(() => import("../Customer/pages/Explore"));
const Checkout = lazy(() => import("../Customer/pages/Checkout"));
const CustomerSettings = lazy(() => import("../Customer/pages/Settings"));

const BaseRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loaders type="bars" size="md" />}>
        <Routes>
          <Route path="/" element={<Marketing />} />
          <Route path="/waitlist" element={<GetWaitlist />} />
          <Route path="/verifyemail" element={<VerifyEmail />} />
          <Route path="/auth" element={<AuthForm />} />
          <Route
            path="/app/forgot-password"
            element={<ForgotCustomerPassword />}
          />
          <Route
            path="/app/resetpassword"
            element={<ResetCustomerPassword />}
          />
          <Route path="/app" element={<PageLayout />}>
            <Route path="categories">
              <Route path=":categoryId" element={<Categories />} />
            </Route>
            <Route path="profile/user">
              <Route
                index
                element={
                  <ProfilePage
                    outfits={userPosts}
                    profileImg={profileBg}
                    userProfileImg={userProfileImg}
                  />
                }
              />
              <Route path="stories/:id" element={<UserStories />} />
            </Route>

            <Route index element={<LandingPage />} />
            <Route path="stories/:id" element={<Stories />} />

            <Route path="look-book">
              <Route index element={<LookBook />} />
              <Route path=":id" element={<UserLooks />} />
              <Route path=":id/:id" element={<LookDetailsCustomer />} />
            </Route>

            <Route path="outfits">
              <Route index element={<Outfits />} />
              <Route path="new-post" element={<NewPost />} />
              <Route path="add-products" element={<AddProduct />} />
              <Route path="my-drafts" element={<Drafts />} />
            </Route>

            <Route path="orders" element={<Orders />} />

            <Route path="shop-by-style">
              <Route index element={<ShopByStyle />} />
              <Route path=":id" element={<Looks />} />
              <Route path=":id/:id" element={<LookDetailsCustomer />} />
            </Route>

            <Route path="buy-product/:id" element={<BuyProduct />} />
            <Route path="shop-by-events" element={<ShopByEvents />} />

            <Route path="shop-by-vendors">
              <Route index element={<ShopByVendors />} />
              <Route path=":id" element={<Vendors />} />
              <Route path=":id/:id" element={<VendorDetails />} />
            </Route>

            <Route path="your-likes" element={<SavedLikedItems />} />
            <Route path="shop-by-products" element={<ShopByProducts />} />
            <Route path="as-seen-on" element={<ShopByStyle />} />
            <Route path="explore" element={<Explore />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="settings" element={<CustomerSettings />} />
          </Route>
          <Route
            path="/vendor/forgot-password"
            element={<ForgotVendorPassword />}
          />
          <Route
            path="/vendor/resetpassword"
            element={<ResetVendorPassword />}
          />

          <Route path="/vendor" element={<VendorPageLayout />}>
            <Route index element={<VendorDashboard />} />
            <Route path="dashboard" element={<VendorDashboard />} />
            <Route path="stylemapping">
              <Route index element={<StyleMapping />} />
              <Route path=":id" element={<StyleMapView />} />
            </Route>

            <Route path="orders">
              <Route index element={<VendorOrders />} />
              <Route path=":id" element={<VendorOrderView />} />
            </Route>
            <Route path="inventory">
              <Route index element={<VendorInventory />} />
              <Route path="add-product" element={<Step1 />} />
              <Route path="step-2" element={<Step2 />} />
            </Route>
            <Route path="customers" element={<VendorCustomers />} />
            <Route path="settings" element={<VendorSettings />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default BaseRoutes;

// Wa1@111111
