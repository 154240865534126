/* eslint-disable no-undef */
import cookies from "js-cookie";
import React, { ReactNode } from "react";
import { useToast, UseToastOptions } from "@chakra-ui/react";

const isBrowser = typeof window !== "undefined";
const host = isBrowser ? window.location.host : "";
let domain: string | undefined;
declare function jwtDecode(token: string): any;

if (host) {
  const domainParts = host.split(".");
  if (domainParts.length > 1) {
    domainParts.shift();
    domain = domainParts.join(".");
  } else {
    domain = host;
  }
}

export const getGreeting = (): string => {
  const date = new Date();
  let greetings = "Good ";
  if (date.getHours() >= 0 && date.getHours() < 12) greetings += "Morning";
  else if (date.getHours() >= 12 && date.getHours() < 16)
    greetings += "Afternoon";
  else if (date.getHours() >= 16) greetings += "Evening";
  return greetings;
};

export const getDecodedAccessToken = (token: string): any | null => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error("Error decoding JWT", error);
    return null;
  }
};

export const isAuth = (): boolean =>
  _getTokenFromSession("token") ? true : false;

export const hasAuthority = (token: string): boolean => {
  const decodedToken = getDecodedAccessToken(token);
  if (!decodedToken) return false; // Token is invalid or expired
  const { role } = decodedToken as { role: string }; // Assuming 'role' is the key for user role in the token
  console.log(role);
  return role.includes("OrganizationAdmin") || role.includes("SuperAdmin");
};

export const _getTokenFromSession = (key: string): string | null =>
  isBrowser ? window.sessionStorage.getItem(key) : null;

export const _removeTokenFromSession = (key: string): void => {
  if (isBrowser) window.sessionStorage.removeItem(key);
};

export const _setTokenToSession = (token: string, name: string): void => {
  if (isBrowser) window.sessionStorage.setItem(name, token);
};

export const _setRefreshTokenToSession = (token: string): void => {
  if (isBrowser) sessionStorage.setItem("refreshToken", JSON.stringify(token));
};

export const _setUserToSession = (data: Record<string, unknown>): void => {
  if (isBrowser) sessionStorage.setItem("user", JSON.stringify(data));
};

export const _getTokenFromStorage = (key: string): string | undefined => {
  return isBrowser ? cookies.get(key) : undefined;
};

export const _setTokenToStorage = (
  key: string,
  value: string,
  expiresAt: Date
): void => {
  if (isBrowser) cookies.set(key, value, { path: "", expires: expiresAt });
};

export const _removeTokenFromStorage = (key: string): void => {
  if (isBrowser) cookies.remove(key, { domain });
};

export const generateActions = (
  action: string
): { REQUEST: string; SUCCESS: string; FAILURE: string } => {
  action = action.toUpperCase();
  return {
    REQUEST: `${action}_REQUEST`,
    SUCCESS: `${action}_SUCCESS`,
    FAILURE: `${action}_FAILURE`,
  };
};

export const capitalize = (s: string): string => {
  return typeof s === "string"
    ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    : "";
};

export const isElement = (element: ReactNode): boolean => {
  return React.isValidElement(element);
};

export const isArray = (data: any): boolean => Array.isArray(data);

export const getInitials = (string: string): string | undefined => {
  if (string) {
    const names = string.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
};

export function calcDate(value: Date | string): string {
  const date1 = new Date();
  const tempDate = new Date(value);
  const diff = Math.floor(date1.getTime() - tempDate.getTime());
  const day = 1000 * 60 * 60 * 24;

  const hours = Math.abs(Math.round(diff / (1000 * 60 * 60)));
  const days = Math.floor(diff / day);
  const months = Math.floor(days / 31);
  const years = Math.floor(months / 12);

  let message = "";

  if (years > 0) {
    message = years + " years";
  } else if (months > 0) {
    message = months + " months ";
  } else if (days > 1) {
    message = days + " days";
  } else if (days === 1) {
    message = "1 day";
  } else if (hours > 1) {
    message = hours + " hours";
  } else {
    message = "1 hour";
  }
  return message;
}

export const useCustomToast = () => {
  const toast = useToast();

  const showToast = (
    message: string,
    type: "success" | "info" | "warning" | "error" | "loading",
    options: UseToastOptions = {}
  ) => {
    const { title, description, status, duration, isClosable, position } =
      options;

    toast({
      title: title || getToastTitle(type),
      description: description || message,
      status: status || getToastStatus(type),
      duration: duration || 5000,
      isClosable: isClosable || true,
      position: position || "top-right",
    });
  };

  const getToastTitle = (
    type: "success" | "info" | "warning" | "error" | "loading"
  ) => {
    switch (type) {
      case "success":
        return "Success";
      case "info":
        return "Information";
      case "warning":
        return "Warning";
      case "error":
        return "Error";
      case "loading":
        return "Loading";
      default:
        return "";
    }
  };

  const getToastStatus = (
    type: "success" | "info" | "warning" | "error" | "loading"
  ) => {
    switch (type) {
      case "success":
        return "success";
      case "info":
        return "info";
      case "warning":
        return "warning";
      case "error":
        return "error";
      case "loading":
        return "loading";
      default:
        return "info";
    }
  };

  return { showToast };
};
