import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { baseApi } from "./baseService";

interface SignupData {
  email: string;
  password: string;
  name?: string;
}

interface SigninData {
  email: string;
  password: string;
}

interface VerifyEmail {
  message?: string;
  token: string;
  status?: string;
  data?: any
}


interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
}

interface ForgotPasswordData {
  email: string;
}

interface ResetPasswordData {
  token: string;
  newPassword: string;
}

interface ResendOtpData {
  email: string;
}

interface AuthResponse {
  message: string;
  token?: string;
  status?: string | number;
  user?: {
    id: string;
    email: string;
    name: string;
  };
}



export const customerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    customerSignup: builder.mutation<AuthResponse, SignupData>({
      query: (data) => ({
        url: "/customer/auth/signup",
        method: "POST",
        body: data,
      }),
    }),

    customerLogin: builder.mutation<AuthResponse, SigninData>({
      query: (data) => ({
        url: "/customer/auth/signin",
        method: "POST",
        body: data,
      }),
    }),


    verifyCustomerEmail: builder.query<VerifyEmail, string>({
      query: (token) => `/customer/auth/verifyemail?token=${token}`,
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const anyError = error as any;
        return {
          message: anyError.data?.message || 'Email verification failed',
          token: '',
        };
      },
      transformResponse: (response: { message: string; token: string }): VerifyEmail => ({
        message: response.message,
        token: response.token || '', // Ensure non-null string
      })
    }),


    changeCustomerPassword: builder.mutation<AuthResponse, ChangePasswordData>({
      query: (data) => ({
        url: "/auth/change-password",
        method: "POST",
        body: data,
        credentials: "include", // Includes cookies for authentication
      }),
    }),

    forgotCustomerPassword: builder.mutation<AuthResponse, ForgotPasswordData>({
      query: (data) => ({
        url: "/customer/auth/forgotpassword",
        method: "POST",
        body: data,
      }),
    }),

    resetCustomerPassword: builder.mutation<AuthResponse, ResetPasswordData>({
      query: (data) => ({
        url: "/customer/auth/resetpassword",
        method: "POST",
        body: data,
      }),
    }),

    resendCustomerOtp: builder.mutation<AuthResponse, ResendOtpData>({
      query: (data) => ({
        url: "/auth/resend-otp",
        method: "POST",
        body: data,
      }),
    }),

    getUser: builder.query({
      query: () => "customer/profile",
    }),
  }),
});

export const {
  useCustomerSignupMutation,
  useCustomerLoginMutation,
  useVerifyCustomerEmailQuery,
  useChangeCustomerPasswordMutation,
  useForgotCustomerPasswordMutation,
  useResetCustomerPasswordMutation,
  useResendCustomerOtpMutation,
} = customerApi;

// import { createSlice } from '@reduxjs/toolkit';
// import { apiSlice } from './apiSlice';

// const initialState = {
//   token: localStorage.getItem('authToken') || '', // Fetch token from localStorage if it exists
// };
// // Define the authSlice
// const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     login: (state, action) => {
//       state.token = action.payload.token;
//       localStorage.setItem('authToken', action.payload.token);
//     },
//     logout: (state) => {
//       state.token = '';
//       localStorage.removeItem('authToken');
//     },
//   },
// });

// // Injecting the login and logout actions
// export const { login, logout } = authSlice.actions;

// // Add authSlice reducer to the store
// export default authSlice.reducer;

// apiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     loginUser: builder.mutation({
//       query: (credentials) => ({
//         url: '/auth/login', // Your login endpoint
//         method: 'POST',
//         body: credentials,
//       }),
//     }),
//     logoutUser: builder.mutation({
//       query: () => ({
//         url: '/auth/logout', // Your logout endpoint
//         method: 'POST',
//       }),
//     }),
//   }),
// });
