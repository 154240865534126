import React from "react";
import "./IconStyles.css";

export const FilterIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0937 8.9375L12.0938 15.6875"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0937 3.3125L12.0938 6.6875"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.90625 13.4375L5.90625 15.6875"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.90625 3.3125L5.90625 11.1875"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.21875 11.1875L7.59375 11.1875"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7812 6.6875L10.4062 6.6875"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SortVertical = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6668 12L10.6668 4M10.6668 4L13.3335 6.75M10.6668 4L8.00016 6.75"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33333 4L5.33333 12M5.33333 12L8 9.25M5.33333 12L2.66667 9.25"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FilterIconHorizontal = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1668 4.66699C12.1668 6.0477 11.0475 7.16699 9.66683 7.16699C8.28612 7.16699 7.16683 6.0477 7.16683 4.66699C7.16683 3.28628 8.28612 2.16699 9.66683 2.16699C11.0475 2.16699 12.1668 3.28628 12.1668 4.66699ZM9.66683 6.16699C10.4953 6.16699 11.1668 5.49542 11.1668 4.66699C11.1668 3.83857 10.4953 3.16699 9.66683 3.16699C8.8384 3.16699 8.16683 3.83856 8.16683 4.66699C8.16683 5.49542 8.8384 6.16699 9.66683 6.16699Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8335 11.3337C3.8335 12.7144 4.95278 13.8337 6.3335 13.8337C7.71421 13.8337 8.8335 12.7144 8.8335 11.3337C8.8335 9.95295 7.71421 8.83366 6.3335 8.83366C4.95278 8.83366 3.8335 9.95295 3.8335 11.3337ZM6.3335 12.8337C5.50507 12.8337 4.8335 12.1621 4.8335 11.3337C4.8335 10.5052 5.50507 9.83366 6.3335 9.83366C7.16192 9.83366 7.8335 10.5052 7.8335 11.3337C7.8335 12.1621 7.16192 12.8337 6.3335 12.8337Z"
        fill="black"
      />
      <path
        d="M9.50016 11.306C9.50016 11.0299 9.72402 10.806 10.0002 10.806H14.6668C14.943 10.806 15.1668 11.0299 15.1668 11.306C15.1668 11.5821 14.943 11.806 14.6668 11.806H10.0002C9.72402 11.806 9.50016 11.5821 9.50016 11.306Z"
        fill="black"
      />
      <path
        d="M6.00016 4.13934C6.27631 4.13934 6.50016 4.3632 6.50016 4.63934C6.50016 4.91548 6.27631 5.13934 6.00016 5.13934L1.3335 5.13934C1.05735 5.13934 0.833496 4.91548 0.833496 4.63934C0.833496 4.3632 1.05735 4.13934 1.3335 4.13934L6.00016 4.13934Z"
        fill="black"
      />
      <path
        d="M0.833496 11.306C0.833496 11.0299 1.05735 10.806 1.3335 10.806H2.66683C2.94297 10.806 3.16683 11.0299 3.16683 11.306C3.16683 11.5821 2.94297 11.806 2.66683 11.806H1.3335C1.05735 11.806 0.833496 11.5821 0.833496 11.306Z"
        fill="black"
      />
      <path
        d="M14.6668 4.13934C14.943 4.13934 15.1668 4.3632 15.1668 4.63934C15.1668 4.91548 14.943 5.13934 14.6668 5.13934H13.3335C13.0574 5.13934 12.8335 4.91548 12.8335 4.63934C12.8335 4.3632 13.0574 4.13934 13.3335 4.13934H14.6668Z"
        fill="black"
      />
    </svg>
  );
};
