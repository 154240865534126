import React, { useRef } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./container.css";
import { FreeMode, Navigation } from "swiper/modules";
import { CardItem } from "./CardSlider";
import { RenderProductItem } from "./RenderProducts";

export interface Product {
  id: string;
  image: string;
  title: string;
  subText?: string;
  price?: string;
  discount?: string;
  isTopSeller?: boolean;
  count?: number;
  ratings?: number;
  tag?: string;
  noOfItems?: number;
  timePosted?: number;
  message?: string;
  fitName?: string;
  username?: string;
}

interface Props {
  products: Product[];
  useCarousel?: boolean;
  index?: number;
}

const AllProducts: React.FC<Props> = ({ products, index, useCarousel }) => {
  const prevRef = useRef<HTMLDivElement | null>(null);
  const nextRef = useRef<HTMLDivElement | null>(null);
  return useCarousel ? (
    <div className="swiper-container">
      {/* {display === "desktop" && (
        <>
          <div
            ref={prevRef}
            className={`custom-prev-btn custom-prev-btn-${index}`}
            aria-label="Previous slide"
          >
            <ArrowLeftIconWhiteBold />
          </div>
          <div
            ref={nextRef}
            className={`custom-next-btn custom-next-btn-${index}`}
            aria-label="Next slide"
          >
            <ArrowRightIconWhite />
          </div>
        </>
      )} */}

      <Swiper
        slidesPerView={2.6}
        spaceBetween={30}
        loop={true}
        freeMode={true}
        modules={[Navigation, FreeMode]}
        className={`mySwiper-${index}`}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper: SwiperType) => {
          (swiper.params.navigation as any).prevEl = prevRef.current;
          (swiper.params.navigation as any).nextEl = nextRef.current;
        }}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <CardItem>
              <RenderProductItem product={product} />
            </CardItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <>
      <Grid
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        gap={{ base: 1, md: 4 }}
        pl={{ base: "2px", md: "0px" }}
        pb="50px"
      >
        {products.map((image, index) => {
          return (
            <GridItem
              key={index}
              bg="white"
              w="100%"
              alignItems="center"
              justifyContent="center"
              onClick={() => console.log("clicked")}
            >
              <RenderProductItem product={image} />
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
};

export default AllProducts;
