import { baseApi } from "./baseService";
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

interface SignupData {
  email: string;
  password: string;
  name?: string;
}

interface SigninData {
  email: string;
  password: string;
}

// disable-eslint-next-line
interface VerifyEmail {
  message: string;
  token: string;
  status?: string;
  data?: any
}


interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
}

interface ForgotPasswordData {
  email: string;
}

interface ResetPasswordData {
  token: string | null;
  newPassword: string;
}

interface ResendOtpData {
  email: string;
}

interface AuthResponse {
  message: string;
  token?: string;
  status?: string;
  user?: {
    id: string;
    email: string;
    name: string;
  };
}

export const vendorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    vendorSignup: builder.mutation<AuthResponse, SignupData>({
      query: (data) => ({
        url: "/vendor/auth/signup",
        method: "POST",
        body: data,
      }),
    }),

    vendorLogin: builder.mutation<AuthResponse, SigninData>({
      query: (data) => ({
        url: "/vendor/auth/signin",
        method: "POST",
        body: data,
      }),
    }),
 
    verifyVendorEmail: builder.query<VerifyEmail, string>({
      query: (token) => `/vendor/auth/verifyemail?token=${token}`,
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const anyError = error as any;
        return {
          message: anyError.data?.message || 'Email verification failed',
          token: '',
        };
      },
      transformResponse: (response: { message: string; token: string }): VerifyEmail => ({
        message: response.message,
        token: response.token || '',
      })
    }),

    changeVendorPassword: builder.mutation<AuthResponse, ChangePasswordData>({
      query: (data) => ({
        url: "/vendor/auth/change-password",
        method: "POST",
        body: data,
        credentials: "include",
      }),
    }),

    forgotVendorPassword: builder.mutation<AuthResponse, ForgotPasswordData>({
      query: (data) => ({
        url: "/vendor/auth/forgotpassword",
        method: "POST",
        body: data,
      }),
    }),

    resetVendorPassword: builder.mutation<AuthResponse, ResetPasswordData>({
      query: (data) => ({
        url: "/vendor/auth/resetpassword",
        method: "POST",
        body: data,
      }),
    }),

    resendVendorOtp: builder.mutation<AuthResponse, ResendOtpData>({
      query: (data) => ({
        url: "/auth/resend-otp",
        method: "POST",
        body: data,
      }),
    }),

    getUser: builder.query({
      query: () => "vendor/profile",
    }),
  }),
});

export const {
  useVendorSignupMutation,
  useVendorLoginMutation,
  useChangeVendorPasswordMutation,
  useForgotVendorPasswordMutation,
  useResetVendorPasswordMutation,
  useResendVendorOtpMutation,
  useVerifyVendorEmailQuery
} = vendorApi;


