import React from "react";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import styled from "styled-components";
import { ShareAsLinkIcon } from "../../assets/Icons/ShareIcon";
import {
  InstagramIcon,
  TelegramIcon,
  WhatsappIcon,
} from "../../assets/Icons/SocialMediaIcons";

const SocialBox = styled(Box)`
  width: Hug (63.07px) px;
  height: Hug (63.07px) px;
  padding: 24px;
  gap: 10px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #ececec;
`;

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ShareModal = ({ isOpen, onClose }: ShareModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />

        <ModalContent
          fontFamily="Geist"
          pb="33px"
          m="auto"
          mx={{ base: "10px", lg: "0px" }}
        >
          <ModalCloseButton />
          <ModalHeader
            fontSize="12px"
            fontWeight={500}
            lineHeight="12px"
            letterSpacing="0.2em"
            textAlign="left"
          >
            SHARE VIA
          </ModalHeader>
          <ModalBody>
            <Flex align="center" justify="center" gap="20px" flexWrap="wrap">
              <SocialBox>
                <ShareAsLinkIcon />
              </SocialBox>
              <SocialBox>
                <WhatsappIcon />
              </SocialBox>
              <SocialBox>
                <TelegramIcon />
              </SocialBox>
              <SocialBox>
                <InstagramIcon />
              </SocialBox>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareModal;
