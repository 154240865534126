import { UnknownAction, Reducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { baseApi } from "./services/baseService";
import authReducer from "./slices/authSlice";
import clearPersistSliceReducer from "./slices/clearPersistSlice";
import { vendorApi } from "./services/vendorAuthService";


const appReducer = combineReducers({
  mainApi: baseApi.reducer,
  auth: authReducer,
  vendorApi: vendorApi.reducer,
  clearPersist: clearPersistSliceReducer,

});

const rootReducer: Reducer = (state: RootState, action: UnknownAction) => {
  if (action.type === "clearPersist/clearAll") {
    storage.removeItem("persist:root");

    state = {} as RootState;
    // return appReducer(undefined, action); // Reset state
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
