import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FilterBtn, ProfilePageContainer } from "../PageStyles";
import { PluggBreadcrumb } from "../../../Components/PluggBreadcrumb";
import styled from "styled-components";
import {
  SmallWhiteInstagramIcon,
  SmallWhiteTikTokIcon,
  SmallWhiteXIcon,
  SmallYoutubeIcon,
} from "../../../assets/Icons/SocialMediaIcons";
import PuggButtons from "../../../Components/PuggButtons";
import { GreenCheckIcon } from "../../../assets/Icons/CheckIcon";
import { useLocation } from "react-router-dom";
import { newProducts, techies } from "../../data";
import { StoryCarousel } from "../../Container/Stories";
import AllProducts from "../../Container/AllProducts";
import { OutfitUI } from "./Outfits";
import Collaborations from "./Collaborations";
import { WhiteEditIcon } from "../../../assets/Icons/EditIcon";
import { uploadDiv } from "../shopByVendor/VendorDetails";
import { WhiteDeleteIcon } from "../../../assets/Icons/DeleteIcon";
import { UploadIcon } from "../../../assets/Icons/UploadIcon";

export const ProfileStats = styled(Text)`
  font-family: Geist;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
`;

export const UserBio = styled(Text)`
  font-family: Geist;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #7f7f7f;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

export const Username = styled(Text)`
  font-family: Geist;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.32px;
  letter-spacing: 0.2em;
  text-align: left;
  text-transform: uppercase;
`;

interface Outfit {
  id: string;
  title: string;
  price?: number;
  tag: string;
  image: string;
  noOfSales: string;
}

interface Props {
  outfits?: Outfit[];
  profileImg?: string;
  userProfileImg?: string;
}

const ProfilePage = ({ outfits = [], profileImg, userProfileImg }: Props) => {
  const display = useBreakpointValue({ base: "mobile", lg: "desktop" });
  const [isSubscribed, setIsSubscribed] = useState(true);
  // eslint-disable-next-line
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [filter, setFilter] = useState<
    "outfits" | "collaborations" | "products"
  >("outfits");
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const currentRoute = pathSegments[pathSegments.length - 1] || "";
  const toast = useToast();

  useEffect(() => {
    const storedImage = localStorage.getItem("profilePicture");
    if (storedImage) {
      setProfilePicture(storedImage);
    }
  }, []);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const updateProfilePicture = (imageUrl: string) => {
    setProfilePicture(imageUrl);
    localStorage.setItem("profilePicture", imageUrl);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        updateProfilePicture(imageUrl);
        onEditClose();
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClearProfilePicture = () => {
    localStorage.removeItem("profilePicture");
    setProfilePicture(null);
  };

  const getSubscriptionButtonProps = () => ({
    btnVariant: isSubscribed ? "primary" : "secondary",
    text: isSubscribed ? "Subscribed" : "Subscribe",
    icon: isSubscribed ? <GreenCheckIcon /> : undefined,
    action: handleSubscription,
    height: "41px",
  });

  const handleSubscription = () => {
    setIsSubscribed(!isSubscribed);
    if (isSubscribed) {
      toast({
        title: "Subscription cancelled.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Subscription successful.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const filterComponents = {
    outfits: <OutfitUI outfits={outfits} />,
    collaborations: <Collaborations />,
    products: (
      <Flex pt="20px" align="center" justify="center">
        <AllProducts products={techies} />
      </Flex>
    ),
  };

  const filteredItems = filterComponents[filter] || null;

  const renderEditModal = () => (
    <Modal isOpen={isEditOpen} onClose={onEditClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent m="auto 20px" w="100%">
        <ModalHeader>
          <Text>UPLOAD IMAGES</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            style={uploadDiv}
            align="center"
            justify="center"
            bg="#f7f7f7"
            color="#919191"
            cursor="pointer"
          >
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              display="none"
              id="upload"
            />

            <Button
              as="label"
              htmlFor="upload"
              colorScheme="transparent"
              color="#919191"
              fontSize="13px"
              fontWeight={500}
              lineHeight="16.12px"
              display="flex"
              flexDirection="column"
            >
              <UploadIcon />
              Upload Profile Picture
            </Button>
          </Flex>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <ProfilePageContainer>
      {display === "desktop" && (
        <Flex
          w="100%"
          py={{ base: "10px", lg: "20px" }}
          pl={{ base: "10px", lg: "20px" }}
        >
          <PluggBreadcrumb />
        </Flex>
      )}
      <Flex direction="column" w="100%" px={{ base: "0px", lg: "25px" }}>
        <Flex
          direction={{ base: "column", sm: "row" }}
          w="100%"
          alignItems="start"
          justifyContent="start"
          gap="32px"
          bg=" linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)"
          h="153px"
          bgImage={profilePicture ? profilePicture : profileImg}
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="cover"
          pos="relative"
        >
          <Flex w="100%" justify="space-between" p="10px">
            <Flex
              color="white"
              gap="20px"
              cursor="pointer"
              bg="#00000036"
              align="center"
              justify="center"
              px="10px"
            >
              <SmallWhiteXIcon />
              <SmallWhiteInstagramIcon />
              <SmallWhiteTikTokIcon />
              <SmallYoutubeIcon />
            </Flex>
            <Flex gap="8px">
              {profilePicture && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  w="39px"
                  h="41px"
                  px="13px"
                  py="12px"
                  bg="#000000"
                  border="0.5px solid #FFFFFF"
                  onClick={handleClearProfilePicture}
                >
                  <WhiteDeleteIcon />
                </Box>
              )}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                w="39px"
                h="41px"
                px="13px"
                py="12px"
                bg="#000000"
                border="0.5px solid #FFFFFF"
                cursor="pointer"
                onClick={onEditOpen}
              >
                <WhiteEditIcon />
                {isEditOpen && renderEditModal()}
              </Box>

              <PuggButtons
                {...getSubscriptionButtonProps()}
                style={{ border: "0.5px solid #FFFFFF", cursor: "pointer" }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          h="35px"
          gap={{ base: "0px", md: "40px" }}
          alignItems="center"
          justifyContent="space-between"
          mb="1em"
          bg="black"
          py="12px"
          px="20px"
        >
          <ProfileStats lineHeight="26px" letterSpacing="20%">
            23K SUBSCRIBERS
          </ProfileStats>
          <ProfileStats>30K SALES</ProfileStats>
        </Flex>
      </Flex>
      {display === "desktop" ? (
        <>
          <Flex w="100%" align="center" justify="space-between" px="20px">
            <Flex gap="9px" color="#7F7F7F">
              <UserBio>Height: 6ft5</UserBio>
              <UserBio>Weight: 65kg</UserBio>
            </Flex>
            <Flex>
              <UserBio color="#3A01DB">Check my size</UserBio>
            </Flex>
          </Flex>
          <Flex
            w="100%"
            align="center"
            justify="center"
            pos="relative"
            h="80px"
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              gap="10px"
              pos="absolute"
              bottom="20px"
            >
              <Image
                src={userProfileImg}
                alt="user-profile-image"
                w="132px"
                h="132px"
              />
              <Username>{currentRoute}</Username>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Flex w="100%" align="center" pl="20px" pb="20px" gap="15px">
            <Image
              src={userProfileImg}
              alt="user-profile-image"
              w="132px"
              h="132px"
              border="2px solid #FFFFFF"
            />
            <Flex direction="column" gap="9px">
              <Username>{currentRoute}</Username>
              <Flex gap="9px" color="#7F7F7F">
                <UserBio>Height: 6ft5</UserBio>
                <UserBio>Weight: 65kg</UserBio>
              </Flex>
              <UserBio color="#3A01DB">Check my size</UserBio>
            </Flex>
          </Flex>
        </>
      )}
      <Flex w="100%" direction="column">
        <Flex
          w="100%"
          gap="40px"
          alignItems="center"
          justifyContent="center"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <FilterBtn
            className={filter === "outfits" ? "active-link" : ""}
            onClick={() => setFilter("outfits")}
          >
            Outfits
          </FilterBtn>
          <FilterBtn
            className={filter === "collaborations" ? "active-link" : ""}
            onClick={() => setFilter("collaborations")}
          >
            Collaborations
          </FilterBtn>
          <FilterBtn
            className={filter === "products" ? "active-link" : ""}
            onClick={() => setFilter("products")}
          >
            Products
          </FilterBtn>
        </Flex>

        {filteredItems}
      </Flex>
    </ProfilePageContainer>
  );
};

export default ProfilePage;

export const UserStories = () => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div style={{ width: "100%", height: "100vh" }}>
        <StoryCarousel stories={newProducts} defaultDuration={5000} />
      </div>
    </div>
  );
};
