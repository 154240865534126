import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import AllProducts from "./AllProducts";
import { techies } from "../data";
import { RenderProductItem } from "./RenderProducts";
import styled from "styled-components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

interface Product {
  id: string;
  title: string;
  image: string;
  // Add other product properties
}

interface Propss {
  isOpen: boolean;
  onClose: () => void;
  products: Product[];
}

// eslint-disable-next-line
const ScrollableModalBody = styled(ModalBody)`
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ProductGrid = styled(Flex)`
  gap: 1.5rem;
  width: 100%;
  flex-wrap: wrap;
`;

const ProductCard = styled.div`
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: all 0.2s;
  flex: 1 1 250px;
  max-width: calc(50% - 0.75rem);

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const PageTitles = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
`;

export const ProductModals: React.FC<Propss> = ({
  isOpen,
  onClose,
  products,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "sm", md: "xl" }}
      isCentered
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent
        // maxHeight="calc(100vh - 80px)" 
        my="40px"
      >
        <ModalHeader>
          <PageTitles>Products</PageTitles>
        </ModalHeader>
        <ModalCloseButton />
        <ScrollableModalBody>
          <ProductGrid>
            {products.map((product) => (
              <ProductCard key={product.id}>
                <Image src={product.image} />
                <h3>{product.title}</h3>
                {/* Add other product details here */}
              </ProductCard>
            ))}
          </ProductGrid>
        </ScrollableModalBody>
      </ModalContent>
    </Modal>
  );
};

export const ProductModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={{ base: "sm", md: "lg" }}>
      <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(3px)" />
      <ModalContent>
        <ModalCloseButton />

        <ModalBody w="100%">
          <Flex
            flexWrap="wrap"
            gap={6}
            w="100%"
            maxHeight="70vh"
            overflow="auto"
          >
            {techies.map((product) => (
              <RenderProductItem key={product.id} product={product} />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const ProductDrawer = ({ isOpen, onClose }: Props) => {
  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size={{ base: "s", lg: "lg" }}>
        <DrawerOverlay bg="blackAlpha.100" backdropFilter="blur(3px)" />

        <DrawerContent
          h="100vh"
          margin="auto"
          mx={{ base: "10px", lg: "0px" }}
          border="ipa xolid red"
        >
          <DrawerCloseButton marginBottom="20px" />

          <DrawerBody overflow="auto">
            <Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              bg="white"
              pt={{ base: "1px", lg: "45px" }}
              mb="5em"
              px={{ base: "0px", lg: "40px" }}
              w="100%"
            >
              <Box w="100%">
                <AllProducts products={techies} />
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const ProductDrawers: React.FC<Props> = ({ isOpen, onClose }) => {
  const display = useBreakpointValue({ base: "mobile", lg: "desktop" });

  const ContentWrapper = display === "mobile" ? Modal : Drawer;
  const BodyWrapper = display === "mobile" ? ModalBody : DrawerBody;
  const HeaderWrapper = display === "mobile" ? ModalHeader : DrawerHeader;
  const CloseButton =
    display === "mobile" ? ModalCloseButton : DrawerCloseButton;

  return (
    <ContentWrapper
      isOpen={isOpen}
      onClose={onClose}
      size={display === "mobile" ? undefined : { base: "s", lg: "lg" }}
    >
      <DrawerOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <DrawerContent h="100vh" margin="auto" mx={{ base: "10px", lg: "0px" }}>
        <CloseButton />
        <HeaderWrapper
          fontSize="18px"
          fontWeight={700}
          lineHeight="22.3px"
          letterSpacing="0.2em"
          textAlign="left"
        >
          PRODUCTS
        </HeaderWrapper>
        <BodyWrapper overflow="auto">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bg="white"
            pt={{ base: "1px", lg: "30px" }}
            mb="5em"
            px={{ base: "0px", lg: "40px" }}
            w="100%"
          >
            <Box w="100%">
              {techies.map((product) => (
                <RenderProductItem key={product.id} product={product} />
              ))}
            </Box>
          </Box>
        </BodyWrapper>
      </DrawerContent>
    </ContentWrapper>
  );
};
