import React from "react";

interface Props {
  fillColor?: string;
  width?: string;
  height?: string;
  color?: string;
}

export const HeartIcon = ({ fillColor = "transparent", color }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4456 19.8655L21.0394 12.2718C22.905 10.3968 23.1769 7.33114 21.4144 5.37176C20.9724 4.87799 20.4343 4.47952 19.8331 4.20071C19.2319 3.9219 18.5801 3.7686 17.9177 3.75018C17.2552 3.73175 16.596 3.84859 15.9802 4.09355C15.3644 4.33851 14.805 4.70645 14.3363 5.17489L12.9113 6.60926L11.6831 5.37176C9.80814 3.50614 6.74251 3.23426 4.78314 4.99676C4.28937 5.43878 3.8909 5.97683 3.61209 6.57804C3.33328 7.17925 3.17998 7.831 3.16155 8.49346C3.14313 9.15592 3.25996 9.81518 3.50492 10.431C3.74988 11.0467 4.11782 11.6061 4.58626 12.0749L12.3769 19.8655C12.5191 20.0063 12.7111 20.0854 12.9113 20.0854C13.1114 20.0854 13.3034 20.0063 13.4456 19.8655Z"
        fill={fillColor}
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MobileNavHeartIcon = ({
  fillColor = "transparent",
  width,
  height,
}: Props) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.901 13.3992L14.5963 7.70391C15.9955 6.29766 16.1994 3.99844 14.8776 2.52891C14.546 2.15858 14.1425 1.85973 13.6916 1.65062C13.2407 1.44151 12.7519 1.32654 12.255 1.31272C11.7582 1.2989 11.2638 1.38653 10.8019 1.57025C10.3401 1.75397 9.92055 2.02992 9.56897 2.38125L8.50022 3.45703L7.57913 2.52891C6.17288 1.12969 3.87366 0.925781 2.40413 2.24766C2.0338 2.57917 1.73495 2.98271 1.52584 3.43362C1.31673 3.88453 1.20176 4.37333 1.18794 4.87018C1.17412 5.36702 1.26174 5.86147 1.44547 6.3233C1.62919 6.78514 1.90514 7.20466 2.25647 7.55625L8.09944 13.3992C8.20608 13.5048 8.35012 13.5641 8.50022 13.5641C8.65032 13.5641 8.79435 13.5048 8.901 13.3992V13.3992Z"
        fill={fillColor}
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CartHeartIcon = ({
  fillColor = "transparent",
  width,
  height,
}: Props) => {
  return (
    <svg
      width={width || "18"}
      height={height || "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 15.1875C9 15.1875 1.96875 11.25 1.96875 6.46876C1.96875 5.62352 2.2616 4.80439 2.79747 4.15073C3.33334 3.49708 4.07913 3.04927 4.90795 2.88351C5.73677 2.71774 6.59743 2.84426 7.34348 3.24153C8.08954 3.6388 8.67491 4.28229 9 5.06251V5.06251C9.32509 4.28229 9.91046 3.6388 10.6565 3.24153C11.4026 2.84426 12.2632 2.71774 13.092 2.88351C13.9209 3.04927 14.6667 3.49708 15.2025 4.15073C15.7384 4.80439 16.0312 5.62352 16.0312 6.46876C16.0312 11.25 9 15.1875 9 15.1875Z"
        fill={fillColor}
        stroke="#676767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
