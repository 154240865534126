/* eslint-disable */
import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import {
  FieldValues,
  // RegisterOptions,
  // UseFormRegister,
  // UseFormRegisterReturn,
} from "react-hook-form";
import styled from "styled-components";

interface ChakraInputProps<T extends FieldValues> {
  placeholder: string;
  register: object;
  // register: UseFormRegister<T>;
  // register: (
  //   name: keyof T,
  //   options?: RegisterOptions<T, keyof T>
  // ) => UseFormRegisterReturn<keyof T>; // Adjusted type for register
  isInvalid: boolean;
  errorMessage: string | undefined;
}

const ChakraInput = <T extends FieldValues>({
  placeholder,
  register,
  isInvalid,
  errorMessage,
}: ChakraInputProps<T>) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <StyledInput placeholder={placeholder} {...register} />
      <FormErrorMessage>{isInvalid && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export const StyledInput = styled(Input)`
  width: 626px;
  height: 48px !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 0px !important;
  flex: 1;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: start;
  font-family: Geist;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.36px;
  text-align: left;

  @media screen and (max-width: 812px) {
    width: 100%;
  }
`;

export { ChakraInput };
