import React from "react";
import InfiniteImageCarousel from "../../Container/InfiniteImageCarousel";
import { mobileImg } from "./Outfits";
import { Flex, Image, Text } from "@chakra-ui/react";
import { OutfitContainer } from "../PageStyles";
import { vendors } from "../../data";

type Props = {};

const Collaborations = (props: Props) => {
  return (
    <Flex pt={{ base: "20px", lg: "0px" }} px={{ base: "0px", lg: "10px" }}>
      <OutfitContainer style={{ boxShadow: "0px 0px 0px 0px transparent" }}>
        <Flex direction="column" gap="40px" className="collab-wrapper">
          {vendors.map((vendor) => (
            <Flex key={vendor.id} direction="column" gap="20px">
              <Flex align="center" gap="15px">
                <Image src={vendor.logo} w="47px" h="47px" />
                <Text textTransform="capitalize">{vendor.title}</Text>
              </Flex>
              <InfiniteImageCarousel
                images={mobileImg.slice(0.3)}
                itemHeight={201}
                itemWidth={199}
                scrollAmount={200}
              />
            </Flex>
          ))}
        </Flex>
      </OutfitContainer>
    </Flex>
  );
};

export default Collaborations;
