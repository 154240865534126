import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { newProducts, techies, vendors } from "../../data";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { PageTitle, FilterBtns } from "../PageStyles";
import { MobileNavHeartIcon } from "../../../assets/Icons/HeartIcon";
import { FilterIcon } from "../../../assets/Icons/FilterIcon";
import PuggModal from "../../../Components/Modal/PuggModal";
import AllProducts from "../../Container/AllProducts";
import { PluggBreadcrumb } from "../../../Components/PluggBreadcrumb";
import PuggButtons from "../../../Components/PuggButtons";
import { EditIcon } from "../../../assets/Icons/EditIcon";
import { DeleteIcon } from "../../../assets/Icons/DeleteIcon";
import { UploadIcon } from "../../../assets/Icons/UploadIcon";

export const uploadDiv = {
  height: "140px",
  width: "100%",
  border: "1px solid #878787",
};

interface Look {
  id: string | number;
  image: string;
  title: string;
  subText?: string;
}

const VendorDetails: React.FC = () => {
  const display = useBreakpointValue({ base: "mobile", lg: "desktop" });
  const [filter, setFilter] = useState<
    "all" | "sweaters" | "pants" | "shoes" | "accessories"
  >("all");
  // eslint-disable-next-line
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [logoPicture, setLogoPicture] = useState<string | null>(null);

  useEffect(() => {
    const storedImage = localStorage.getItem("profilePicture");
    const storedLogo = localStorage.getItem("logoPicture");
    if (storedImage) setProfilePicture(storedImage);
    if (storedLogo) setLogoPicture(storedLogo);
  }, []);

  //   const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, setImage: React.Dispatch<React.SetStateAction<string | null>>, storageKey: string) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const imageUrl = reader.result as string;
  //       setImage(imageUrl);
  //       localStorage.setItem(storageKey, imageUrl);
  //     };
  //     reader.readAsDataURL(file);
  //     onEditClose();
  //   }
  // };

  const handleProfileImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        setProfilePicture(imageUrl);
        localStorage.setItem("profilePicture", imageUrl);
      };
      reader.readAsDataURL(file);
      onEditClose();
    }
  };

  const handleLogoImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        setLogoPicture(imageUrl);
        localStorage.setItem("logoPicture", imageUrl);
      };
      reader.readAsDataURL(file);
      onEditClose();
    }
  };

  const handleClearProfilePicture = () => {
    localStorage.removeItem("profilePicture");
    localStorage.removeItem("logoPicture");
    setProfilePicture(null);
    setLogoPicture(null);
  };

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isSizeOpen,
    onOpen: onSizeOpen,
    onClose: onSizeClose,
  } = useDisclosure();
  const {
    isOpen: isPriceOpen,
    onOpen: onPriceOpen,
    onClose: onPriceClose,
  } = useDisclosure();
  const {
    isOpen: isColorOpen,
    onOpen: onColorOpen,
    onClose: onColorClose,
  } = useDisclosure();

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const { id } = useParams<{ id: string }>();

  const lookDetails = vendors.find((look: Look) => look.title === id);

  const [heartIconColors, setHeartIconColors] = useState<
    Record<string, string>
  >({});

  const handleHeartIconClick = (id: string) => {
    setHeartIconColors((prevColors) => ({
      ...prevColors,
      [id]: prevColors[id] === "#DE0000" ? "DE0000" : "#DE0000",
    }));
  };

  if (!lookDetails) {
    return <p>Look not found</p>;
  }

  const filteredItems = () => {
    switch (filter) {
      case "sweaters":
      case "shoes":
        return newProducts;
      case "pants":
      case "accessories":
        return techies;
      case "all":
      default:
        return [...newProducts, ...techies];
    }
  };

  const filterBtns = () => (
    <>
      <FilterBtns
        color={display === "mobile" ? "#878787" : "white"}
        fontSize={{ base: "11px", lg: "13px" }}
      >
        FILTER by
      </FilterBtns>
      {["size", "price", "color"].map((filterType) => (
        <FilterBtns
          key={filterType}
          color={display === "mobile" ? "black" : "white"}
          fontSize={{ base: "11px", lg: "13px" }}
          onClick={() => {
            if (filterType === "size") onSizeOpen();
            if (filterType === "price") onPriceOpen();
            if (filterType === "color") onColorOpen();
          }}
        >
          {filterType}
        </FilterBtns>
      ))}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isSizeOpen}
        onClose={onSizeClose}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <PuggModal>
          <h1>filter by size</h1>
        </PuggModal>
      </Modal>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isPriceOpen}
        onClose={onPriceClose}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <PuggModal>
          <h1>filter by price</h1>
        </PuggModal>
      </Modal>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isColorOpen}
        onClose={onColorClose}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <PuggModal>
          <h1>filter by color</h1>
        </PuggModal>
      </Modal>
    </>
  );

  const editmodal = (
    <Modal isOpen={isEditOpen} onClose={onEditClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent m="auto 20px" w="100%">
        <ModalHeader
          fontFamily="Geist"
          fontSize="13px"
          fontWeight={500}
          lineHeight="24px"
          letterSpacing="0.2em"
          textTransform="uppercase"
          textAlign="center"
          pt="20px"
        >
          <Text as="p">UPLOAD IMAGES</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap="16px" direction="column">
            <Flex
              style={uploadDiv}
              align="center"
              justify="center"
              bg="#f7f7f7"
              color="#919191"
              cursor="pointer"
            >
              <Input
                type="file"
                accept="image/*"
                onChange={handleProfileImageUpload}
                display="none"
                id="upload"
              />

              <Button
                as="label"
                htmlFor="upload"
                colorScheme="transparent"
                color="#919191"
                fontSize="13px"
                fontWeight={500}
                lineHeight="16.12px"
                display="flex"
                flexDirection="column"
              >
                <UploadIcon />
                Upload Profile Picture
              </Button>
            </Flex>
            <Flex
              style={uploadDiv}
              align="center"
              justify="center"
              bg="#f7f7f7"
              color="#919191"
              cursor="pointer"
            >
              <Input
                type="file"
                accept="image/*"
                onChange={handleLogoImageUpload}
                display="none"
                id="logo"
              />

              <Button
                as="label"
                htmlFor="logo"
                colorScheme="transparent"
                color="#919191"
                fontSize="13px"
                fontWeight={500}
                lineHeight="16.12px"
                display="flex"
                flexDirection="column"
              >
                <UploadIcon />
                Upload Profile Logo
              </Button>
            </Flex>
          </Flex>
        </ModalBody>

        <Flex
          direction="column"
          gap="8px"
          px="20px"
          pb="20px"
          color="#8F8F8F"
          fontSize="13px"
          fontWeight={500}
          lineHeight="21px"
        >
          <Text>Images must not more than 2mb</Text>
          <Text>Cover image size: 70/70 PX</Text>
        </Flex>
      </ModalContent>
    </Modal>
  );

  return (
    <VStack
      boxShadow="0px 6.05px 20.74px 0px #0000000D"
      bg="white"
      w="100%"
      h="100%"
      p={{ base: "0px", sm: "30px" }}
      gap={0}
    >
      {display === "desktop" && (
        <>
          <Flex w="100%" pb={{ base: "0px", lg: "20px" }}>
            {display === "desktop" && <PluggBreadcrumb />}
          </Flex>
          <Flex
            direction={{ base: "column", sm: "row" }}
            w="100%"
            alignItems="center"
            justifyContent="start"
            gap="32px"
            h="247px"
            bgImage={profilePicture ? profilePicture : lookDetails.image}
            bgRepeat="no-repeat"
            bgPosition="center top -60px"
            bgSize="cover"
            pos="relative"
          >
            <Flex
              w="100%"
              h="100%"
              bg="linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%)"
            >
              <Flex w="100%" px="10px" direction="column" m="40px">
                <Flex align="center" justify="space-between" py="20px">
                  <Flex gap="20px" align="center" justify="center">
                    <Image
                      src={logoPicture ? logoPicture : lookDetails.logo}
                      alt={lookDetails.title}
                      w="48px"
                      h="43px"
                      border="1px solid #FFFFFF"
                    />
                    <PageTitle style={{ color: "white" }}>
                      {lookDetails.title}
                    </PageTitle>
                  </Flex>
                  <Flex gap="8px">
                    {profilePicture ||
                      (logoPicture && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          w="39px"
                          h="41px"
                          px="13px"
                          py="12px"
                          bg="#FFFFFFD1"
                          onClick={handleClearProfilePicture}
                        >
                          <DeleteIcon />
                        </Box>
                      ))}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="39px"
                      h="41px"
                      px="13px"
                      py="12px"
                      bg="#FFFFFFD1"
                      onClick={onEditOpen}
                    >
                      <EditIcon />
                      {editmodal}
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="39px"
                      h="41px"
                      px="13px"
                      py="12px"
                      bg="#FFFFFFD1"
                    >
                      <FilterIcon />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="39px"
                      h="41px"
                      px="13px"
                      py="12px"
                      bg="#FFFFFFD1"
                      onClick={() => handleHeartIconClick("icon1")}
                    >
                      <MobileNavHeartIcon
                        fillColor={heartIconColors["icon1"] || "transparent"}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  h="100%"
                  align="center"
                  justify="space-between"
                  color="black"
                  textTransform="uppercase"
                  py="20px"
                >
                  {filterBtns()}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}

      {display === "mobile" && (
        <>
          <Flex
            direction={{ base: "column", sm: "row" }}
            w="100%"
            h="185px"
            alignItems="center"
            justifyContent="start"
            gap="32px"
            bgImage={lookDetails.image}
            bgRepeat="no-repeat"
            bgPosition="center top -20px"
            bgSize="cover"
            pos="relative"
          >
            <Flex
              w="100%"
              h="100%"
              bg="linear-gradient(27deg, #000000 0%, rgba(0, 0, 0, 0) 100%)"
            >
              <Flex w="100%" px="10px" direction="column" m="40px">
                <Flex align="center" justify="space-between" p="20px">
                  <Flex
                    pos="absolute"
                    bottom="30px"
                    left="25%"
                    transform="translateX(-50%)"
                    gap="20px"
                    align="center"
                    justify="center"
                  >
                    <Image
                      src={lookDetails.logo}
                      alt={lookDetails.title}
                      w="48px"
                      h="43px"
                      border="1px solid #FFFFFF"
                    />
                    <PageTitle style={{ color: "white" }}>
                      {lookDetails.title}
                    </PageTitle>
                  </Flex>
                  <Flex gap="8px" pos="absolute" top="20px" right="20px">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="39px"
                      h="41px"
                      px="13px"
                      py="12px"
                      bg="#FFFFFFD1"
                      onClick={onEditOpen}
                    >
                      <EditIcon />
                      {editmodal}
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="39px"
                      h="41px"
                      px="13px"
                      py="12px"
                      bg="#FFFFFFD1"
                    >
                      <FilterIcon />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="39px"
                      h="41px"
                      px="13px"
                      py="12px"
                      bg="#FFFFFFD1"
                      onClick={() => handleHeartIconClick("icon1")}
                    >
                      <MobileNavHeartIcon
                        fillColor={heartIconColors["icon1"] || "transparent"}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            py="10px"
            w="100%"
            h="100%"
            align="center"
            justify="space-around"
            color="black"
            textTransform="uppercase"
            px={{ base: "10px", md: "20px" }}
            mb="20px"
            bg="#F8F8F8"
          >
            {filterBtns()}
          </Flex>
        </>
      )}

      <Flex
        w="100%"
        gap="10px"
        align="center"
        justify="left"
        mb="1.5em"
        mt={{ base: "0em", lg: "1em" }}
        pt={{ base: "0em", lg: "1em" }}
        overflowX="scroll"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none",
          scrollbarWidth: "none",
        }}
        pl={{ base: "10px", lg: "0px" }}
      >
        <PuggButtons
          text="All"
          btnVariant={filter === "all" ? "primary" : "outline"}
          action={() => setFilter("all")}
          radius="10px"
          wid="fit-content"
        />
        <PuggButtons
          text="Sweaters"
          btnVariant={filter === "sweaters" ? "primary" : "outline"}
          action={() => setFilter("sweaters")}
          radius="10px"
          wid="fit-content"
        />
        <PuggButtons
          text="Pants"
          btnVariant={filter === "pants" ? "primary" : "outline"}
          action={() => setFilter("pants")}
          radius="10px"
          wid="fit-content"
        />
        <PuggButtons
          text="Shoes"
          btnVariant={filter === "shoes" ? "primary" : "outline"}
          action={() => setFilter("shoes")}
          radius="10px"
          wid="fit-content"
        />
        <PuggButtons
          text="Accessories"
          btnVariant={filter === "accessories" ? "primary" : "outline"}
          action={() => setFilter("accessories")}
          radius="10px"
          wid="fit-content"
        />
      </Flex>

      <Flex gap="14px" alignItems="center" justifyContent="center">
        <AllProducts products={filteredItems()} />
      </Flex>
    </VStack>
  );
};

export default VendorDetails;
