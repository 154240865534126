const currentDate = new Date();

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const weekday = weekdays[currentDate.getDay()];
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  export const formattedDate = `${weekday}, ${month} ${year}`;

  export const hasSpecialCharacter = (value: any) => {
    return /[!@#$%^&*()\-_=+{};:,<.>]/.test(value);
};

export const hasCaps = (value: any) => {
    return /(.*[A-Z].*)/.test(value);
};

export const hasLowerCase = (value: any) => {
    return /(.*[a-z].*)/.test(value);
};

export const hasDigit = (value: any) => {
    return /(.*\d.*)/.test(value);
};


export function truncateText(text: string, limit: number = 33): string {
  if (text.length <= limit) {
    return text;
  }
  return text.slice(0, limit) + "...";
}

// const result = truncateText("Topman faux leather shacket in black");
// console.log(result); // Output: "Topman faux leather shacket i..."


