import React from "react";

type Props = {};

export const DeleteIcon = (props: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1875 3.9375H2.8125"
        stroke="#676767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3125 7.3125V11.8125"
        stroke="#676767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6875 7.3125V11.8125"
        stroke="#676767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0625 3.9375V14.625C14.0625 14.7742 14.0032 14.9173 13.8977 15.0227C13.7923 15.1282 13.6492 15.1875 13.5 15.1875H4.5C4.35082 15.1875 4.20774 15.1282 4.10225 15.0227C3.99676 14.9173 3.9375 14.7742 3.9375 14.625V3.9375"
        stroke="#676767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8125 3.9375V2.8125C11.8125 2.51413 11.694 2.22798 11.483 2.017C11.272 1.80603 10.9859 1.6875 10.6875 1.6875H7.3125C7.01413 1.6875 6.72798 1.80603 6.51701 2.017C6.30603 2.22798 6.1875 2.51413 6.1875 2.8125V3.9375"
        stroke="#676767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const WhiteDeleteIcon = (props: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1875 3.9375H2.8125"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3125 7.3125V11.8125"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6875 7.3125V11.8125"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0625 3.9375V14.625C14.0625 14.7742 14.0032 14.9173 13.8977 15.0227C13.7923 15.1282 13.6492 15.1875 13.5 15.1875H4.5C4.35082 15.1875 4.20774 15.1282 4.10225 15.0227C3.99676 14.9173 3.9375 14.7742 3.9375 14.625V3.9375"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8125 3.9375V2.8125C11.8125 2.51413 11.694 2.22798 11.483 2.017C11.272 1.80603 10.9859 1.6875 10.6875 1.6875H7.3125C7.01413 1.6875 6.72798 1.80603 6.51701 2.017C6.30603 2.22798 6.1875 2.51413 6.1875 2.8125V3.9375"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const XIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0625 4.4375L3.9375 14.5625"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0625 14.5625L3.9375 4.4375"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
