import { useEffect } from "react";
import { vendorApi } from "./vendorAuthService";
import { useAppDispatch } from "../hooks";
import { customerApi } from "./customerAuthService";

export const InitializeAuth = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userType = localStorage.getItem("userType"); // Add this to store user type

    if (token && userType) {
      try {
        if (userType === "vendor") {
          dispatch(vendorApi.endpoints.getUser.initiate(null))
            .unwrap()
            .catch(() => {
              localStorage.removeItem("token");
              localStorage.removeItem("userType");
            });
        } else if (userType === "customer") {
          dispatch(customerApi.endpoints.getUser.initiate(null))
            .unwrap()
            .catch(() => {
              localStorage.removeItem("token");
              localStorage.removeItem("userType");
            });
        }
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("userType");
      }
    }
  }, [dispatch]);

  return null;
};
