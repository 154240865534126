import img1 from "../assets/images/image 1054.png";
import img2 from "../assets/images/image 1016.png";
import img3 from "../assets/images/image 1046.png";
import img4 from "../assets/images/image 1044.png";
import img5 from "../assets/images/image 1017.png";
import events1 from "../assets/images/events1.png";
import events2 from "../assets/images/events2.png";
import events3 from "../assets/images/events3.png";
import events4 from "../assets/images/events4.png";
import events5 from "../assets/images/events5.png";

import vendCat1 from "../assets/images/vendorType1.png";
import vendCat2 from "../assets/images/vendorType2.png";
import vendCat3 from "../assets/images/vendorType3.png";
import vendCat4 from "../assets/images/vendorType4.png";

import vend1 from "../assets/images/vendor1.png";
import vend1Logo from "../assets/images/vendor1-1.png";

import vend2 from "../assets/images/vendor2.png";
import vend2Logo from "../assets/images/vendor2-1.png";

import vend3 from "../assets/images/vendor3.png";
import vend3Logo from "../assets/images/vendor3-1.png";

import vend4 from "../assets/images/vendor4.png";
import vend4Logo from "../assets/images/vendor4-1.png";

import vend5 from "../assets/images/vendor5.png";
import vend5Logo from "../assets/images/vendor5-1.png";

import vend6 from "../assets/images/vendor6.png";
import vend6Logo from "../assets/images/vendor6-1.png";

import prod1 from "../assets/images/prod1.png";
import prod2 from "../assets/images/prod2.png";
import prod3 from "../assets/images/prod3.png";
import prod4 from "../assets/images/prod4.png";
import prod5 from "../assets/images/prod5.png";
import stories1 from "../assets/images/stor1.png";
import stories2 from "../assets/images/stor2.png";
import stories3 from "../assets/images/stor3.png";
import stories4 from "../assets/images/stor4.png";
import stories5 from "../assets/images/stor5.png";
import stories6 from "../assets/images/stor6.png";
import tech1 from "../assets/images/techlk1.png";
import tech2 from "../assets/images/techlk2.png";
import tech3 from "../assets/images/techlk3.png";
import tech4 from "../assets/images/techlk4.png";
import tech5 from "../assets/images/techlk5.png";
import tech6 from "../assets/images/techlk6.png";
import tech7 from "../assets/images/techlk7.png";
import tech8 from "../assets/images/techlk8.png";
import tech9 from "../assets/images/techlk9.png";
import tech10 from "../assets/images/techlk10.png";
import tech11 from "../assets/images/techlk11.png";
import tech12 from "../assets/images/techlk12.png";
import tech13 from "../assets/images/techlk13.png";
import tech14 from "../assets/images/techlk14.png";
import tech15 from "../assets/images/techlk15.png";
import techlk1 from "../assets/images/lk-1.png";
import techlk2 from "../assets/images/lk-2.png";
import techlk3 from "../assets/images/lk-3.png";
import techlk4 from "../assets/images/lk-4.png";
import techlk5 from "../assets/images/lk-5.png";
import techlk6 from "../assets/images/lk-6.png";
import techlk7 from "../assets/images/lk-7.png";
import techlk8 from "../assets/images/lk-8.png";
import techlk9 from "../assets/images/lk-9.png";
import techlk10 from "../assets/images/lk-10.png";
import techlk11 from "../assets/images/lk-11.png";
import techlk12 from "../assets/images/lk-1.png";
import style1 from "../assets/images/street-wear.png";
import style2 from "../assets/images/gym-wear.png";
import style3 from "../assets/images/minimalist.png";
import style4 from "../assets/images/tech-bro.png";
import style5 from "../assets/images/date-night.png";
import style6 from "../assets/images/gentleman.png";
import wmn1 from '../assets/images/women1.png'
import wmn2 from '../assets/images/women2.png'
import wmn3 from '../assets/images/women3.png'
import wmn4 from '../assets/images/women4.png'
import wmn5 from '../assets/images/women5.png'
import wmn6 from '../assets/images/women6.png'


import fit1 from "../assets/images/outfit1.png";
import fit2 from "../assets/images/outfit2.png";
import fit3 from "../assets/images/outfit3.png";

import lkbk1 from '../assets/images/lkbk1.png'
import lkbk2 from '../assets/images/lkbk2.png'
import lkbk3 from '../assets/images/lkbk3.png'
import lkbk4 from '../assets/images/lkbk4.png'
import lkbk5 from '../assets/images/lkbk5.png'
import lkbk6 from '../assets/images/lkbk6.png'
import lkbk7 from '../assets/images/lkbk7.png'
import lkbk8 from '../assets/images/lkbk8.png'

import prd1 from '../assets/images/prd1.png'
import prd2 from '../assets/images/prd2.png'
import prd3 from '../assets/images/prd3.png'
import prd4 from '../assets/images/prd4.png'
import prd5 from '../assets/images/prd5.png'
import prd6 from '../assets/images/prd6.png'
import prd7 from '../assets/images/prd7.png'
import prd8 from '../assets/images/prd8.png'
import prd9 from '../assets/images/prd9.png'
import prd10 from '../assets/images/prd10.png'
import prd11 from '../assets/images/prd11.png'
import prd12 from '../assets/images/prd12.png'
import prd13 from '../assets/images/prd13.png'

interface Outfit {
  id: string;
  image: string[];
  title: string;
  duration?: number;
  noOfSales?: number;
  tag: string;
}

export const userStories: Outfit[] = [
  {
    image: [tech3, prd1, prd2, prd3],
    title: "RealAJ",
    id: "tech-003",
    duration: 7000,
    tag: 'office', 
  },
  {
    image: [tech7, prd4, prd5, prd6],
    title: "RealAJ",
    id: "tech-007",
    duration: 7000,
    tag: 'office', 
  },
  {
    image: [tech10, prd7, prd8, prd9],
    title: "RealAJ",
    id: "tech-010",
    duration: 7000,
    tag: 'office', 
  },
  {
    image: [tech12, prd10, prd11, prd12],
    title: "RealAJ",
    id: "tech-012",
    duration: 7000,
    tag: 'office', 
  },
  {
    image: [techlk1, prd13, lkbk1, lkbk2],
    title: "Nosa.Styles",
    id: "stor-001",
    duration: 3000,
    tag: 'office', 
  },
  {
    image: [techlk2, lkbk3, lkbk4, lkbk5],
    title: "Nosa.Styles",
    id: "stor-002",
    tag: 'office', 
  },
  // {
  //   image: techlk3,
  //   title: "Nosa.Styles",
  //   id: "stor-003",
  //   duration: 7000,
  // },
  // {
  //   image: techlk4,
  //   title: "Nosa.Styles",
  //   id: "stor-004",
  //   duration: 3000,
  // },
  // {
  //   image: techlk5,
  //   title: "Nosa.Styles",
  //   id: "stor-005",
  // },
  // {
  //   image: techlk6,
  //   title: "Nosa.Styles",
  //   id: "stor-006",
  //   duration: 3700,
  // },
];


export const newProducts = [
  {
    image: prd1,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
    tag: 'shoes',
    isTopseller: true,
    id: "tech-001",
  },
  {
    image: prd2,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
    tag: 'pants',
    isTopseller: true,
    id: "tech-002",
  },
  {
    image: prd3,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
    tag: 'sweaters',
    isTopseller: true,
    id: "tech-003",
  },
  {
    image: prd4,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
    tag: 'accessories',
    isTopseller: true,
    id: "tech-004",
  },
  {
    image: prd5,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
    tag: 'sweaters',
    isTopseller: true,
    id: "tech-005",
  },
  {
    image: prd6,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
    tag: 'pants',
    isTopseller: true,
    id: "tech-006",
  },
  {
    image: prd7,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
    tag: 'shoes',
    isTopseller: true,
    id: "tech-007",
  },
  {
    image: prd8,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
    tag: 'accessories',
    isTopseller: true,
    id: "tech-008",
  },
  {
    image: prd9,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
     tag: 'sweaters',
    isTopseller: true,
    id: "tech-009",
  },
  {
    image: prd10,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
     tag: 'pants',
    isTopseller: true,
    id: "tech-010",
  },
  {
    image: prd11,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
     tag: 'shoes',
    isTopseller: true,
    id: "tech-011",
  },
  {
    image: prd12,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
     tag: 'accessories',
    isTopseller: true,
    id: "tech-012",
  },
  {
    image: prd13,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
     tag: 'sweaters',
    isTopseller: true,
    id: "tech-013",
  },
];

export const lookBookAvatars = [
  {image: lkbk1, id: 'lkbk-001', title: "Ruger's LookBook", noOfLooks: 22},
  {image: lkbk2, id: 'lkbk-002', title: "Bella's Closet", noOfLooks: 13},
  {image: lkbk3, id: 'lkbk-003', title: "Black's Closet", noOfLooks: 40},
  {image: lkbk4, id: 'lkbk-004', title: "Waliyah's Closet", noOfLooks: 10},
  {image: lkbk5, id: 'lkbk-005', title: "BNXN's LookBook", noOfLooks: 22},
  {image: lkbk6, id: 'lkbk-006', title: "ZT's LookBook", noOfLooks: 13},
  {image: lkbk7, id: 'lkbk-007', title: "Nika's LookBook", noOfLooks: 40},
  {image: lkbk8, id: 'lkbk-008', title: "Waliyah's LookBook", noOfLooks: 10},
]

export const userOutfits = [
  {
    image: fit2,
    username: "Tobi",
    fitName: "fit name",
    noOfItems: 4,
    timePosted: 2,
    tag: 'office',
    message: "Today, I’m excited to share the insipirations for the summer",
    id: "fit-001",
  },
  {
    image: fit3,
    username: "Tobi",
    fitName: "fit name",
    noOfItems: 4,
     timePosted: 3,
     tag: 'party',
    message: "Today, I’m excited to share the insipirations for the summer",
    id: "fit-002",
  },
  {
    image: fit1,
    username: "Tobi",
    fitName: "fit name",
    noOfItems: 4,
     timePosted: 1,
     tag: 'class',
    message: "Today, I’m excited to share the insipirations for the summer",
    id: "fit-003",
  },
  {
    image: fit2,
    username: "Tobi",
    fitName: "fit name",
    noOfItems: 4,
     timePosted: 5,
     tag: 'tgif',
    message: "Today, I’m excited to share the insipirations for the summer",
    id: "fit-004",
  },
  {
    image: fit3,
    username: "Tobi",
    fitName: "fit name",
    noOfItems: 4,
     timePosted: 1,
     tag: 'formal',
    message: "Today, I’m excited to share the insipirations for the summer",
    id: "fit-005",
  },
  {
    image: fit1,
    username: "Tobi",
    fitName: "fit name",
    noOfItems: 4,
     timePosted: 4,
     tag: 'top post',
    message: "Today, I’m excited to share the insipirations for the summer",
    id: "fit-006",
  },
  {
    image: fit2,
    username: "Tobi",
    fitName: "fit name",
    noOfItems: 4,
     timePosted: 3,
     tag: 'for all',
    message: "Today, I’m excited to share the insipirations for the summer",
    id: "fit-007",
  },
];


export const womenSstyles = [
  {
    image: wmn1,

    id: "wmn-001",
  },
  {
    image: wmn2,

    id: "wmn-002",
  },
  {
    image: wmn3,

    id: "wmn-003",
  },
  {
    image: wmn4,

    id: "wmn-004",
  },
  {
    image: wmn5,

    id: "wmn-005",
  },
  {
    image: wmn6,

    id: "wmn-006",
  },
];

export const styles = [
  {
    image: style1,
    title: 'street wear',
    id: "st-001",
  },
  {
    image: style2,
    title: 'gym wear',
    id: "st-002",
  },
  {
    image: style3,
    title: 'minimalist',
    id: "st-003",
  },
  {
    image: style4,
    title: 'tech bro',
    id: "st-004",
  },
  {
    image: style5,
    title: 'date night',
    id: "st-005",
  },
  {
    image: style6,
    title: 'gentlemen',
    id: "st-006",
  },
];

export const looks = [
  {
    image: img1,
    title: "Tech looks",
    id: "lk-001",
  },
  {
    image: img2,
    title: "Office looks",
    id: "lk-002",
  },
  {
    image: img3,
    title: "Date night",
    id: "lk-003",
  },
  {
    image: img4,
    title: "Date night",
    id: "lk-004",
  },
  {
    image: img5,
    title: "Date night",
    id: "lk-005",
  },
];

export const events = [
  {
    image: events1,
    title: "Tech looks",
    id: "lk-001",
  },
  {
    image: events2,
    title: "Office looks",
    id: "lk-002",
  },
  {
    image: events3,
    title: "Date night",
    id: "lk-003",
  },
  {
    image: events4,
    title: "Date night",
    id: "lk-004",
  },
  {
    image: events5,
    title: "Date night",
    id: "lk-005",
  },
];

export const vendorCategory = [
  {
    image: vendCat1,
    title: "sneaker vendors",
    itemsSold: 'sneakers',
    id: "vndCt-001",
    count: 230,
    ratings: 4.5,
  },
  {
    image: vendCat2,
    title: "formal wear vendors",
    itemsSold: 'formal wear',
    id: "vndCt-002",
    count: 230,
    ratings: 4.5,
  },
  {
    image: vendCat3,
    title: "casual shirt vendors",
    itemsSold: 'casual shirt',
    id: "vndCt-003",
    count: 230,
    ratings: 4.5,
  },
  {
    image: vendCat4,
    title: "active wear vendors",
    itemsSold: 'active wear',
    id: "vndCt-004",
    count: 230,
    ratings: 4.5,
  },
  {
    image: vendCat1,
    title: "sneaker vendors",
    itemsSold: 'sneakers',
    id: "vndCt-005",
    count: 230,
    ratings: 4.5,
  },
  {
    image: vendCat2,
    title: "formal wear vendors",
    itemsSold: 'formal wear',
    id: "vndCt-006",
    count: 230,
    ratings: 4.5,
  },
  {
    image: vendCat3,
    title: "casual shirt vendors",
    itemsSold: 'casual shirt',
    id: "vndCt-007",
    count: 230,
    ratings: 4.5,
  },
  {
    image: vendCat4,
    title: "active wear vendors",
    itemsSold: 'active wear',
    id: "vndCt-008",
    count: 230,
    ratings: 4.5,
  },

];
export const vendors = [
  {
    image: vend1,
    logo: vend1Logo,
    title: "honey love",
    itemsSold: 'Footwear, Clothes, Headgear',
    noOfItems: 43,
    noOfSales: 333,
    id: "vnd-001",
  },
  {
    image: vend2,
    logo: vend2Logo,
    title: "hosfar",
    itemsSold: 'Footwear, Clothes, Headgear',
     noOfItems: 43,
    noOfSales: 333,
    id: "vnd-002",
  },
  {
    image: vend3,
    logo: vend3Logo,
    title: "missouri",
    itemsSold: 'Footwear, Clothes, Headgear',
     noOfItems: 43,
    noOfSales: 333,
    id: "vnd-003",
  },
  {
    image: vend4,
    logo: vend4Logo,
    title: "honeylove",
    itemsSold: 'Footwear, Clothes, Headgear',
     noOfItems: 43,
    noOfSales: 333,
    id: "vnd-004",
  },
  {
    image: vend5,
    logo: vend5Logo,
    title: "the yutes",
    itemsSold: 'Footwear, Clothes, Headgear',
     noOfItems: 43,
    noOfSales: 333,
    id: "vnd-005",
  },
  {
    image: vend6,
    logo: vend6Logo,
    title: "mattel creations",
    itemsSold: 'Footwear, Clothes, Headgear',
     noOfItems: 43,
    noOfSales: 333,
    id: "vnd-006",
  },
];


export const products = [
  {
    image: prod1,
    title: "N19,000",
    subText: "Shein Classic Dress (L)",
    id: "prod-001",
  },
  {
    image: prod2,
    title: "N19,000",
    subText: "Shein Classic Dress (L)",

    id: "prod-002",
  },
  {
    image: prod3,
    title: "N19,000",
    subText: "Shein Classic Dress (L)",
    id: "prod-003",
  },
  {
    image: prod4,
    title: "N19,000",
    subText: "Shein Classic Dress (L)",
    id: "prod-004",
  },
  {
    image: prod5,
    title: "N19,000",
    subText: "Shein Classic Dress (L)",
    id: "prod-005",
  },
];

export const stories = [
  {
    image: stories1,
    title: "Nosa.Styles",
    id: "stor-001",
  },
  {
    image: stories2,
    title: "Noah",
    id: "stor-002",
  },
  {
    image: stories3,
    title: "Silk",
    id: "stor-003",
  },
  {
    image: stories4,
    title: "Okafor",
    id: "stor-004",
  },
  {
    image: stories5,
    title: "Nosa.Styles",
    id: "stor-005",
  },
  {
    image: stories6,
    title: "RealAJ",
    id: "stor-006",
  },
  {
    image: stories1,
    title: "Nosa.Styles",
    id: "stor-007",
  },
  {
    image: stories2,
    title: "Noah",
    id: "stor-008",
  },
  {
    image: stories3,
    title: "Silk",
    id: "stor-009",
  },
  {
    image: stories4,
    title: "Okafor",
    id: "stor-010",
  },
  {
    image: stories5,
    title: "Nosa.Styles",
    id: "stor-011",
  },
  {
    image: stories6,
    title: "RealAJ",
    id: "stor-012",
  },
];
export const tech = [
  {
    image: tech1,
    title: "RealAJ",
    id: "tech-001",
  },
  {
    image: tech2,
    title: "RealAD",
    id: "tech-002",
  },
  {
    image: tech3,
    title: "RealAE",
    id: "tech-003",
  },
  {
    image: tech4,
    title: "RealAF",
    id: "tech-004",
  },
  {
    image: tech5,
    title: "RealAG",
    id: "tech-005",
  },
  {
    image: tech6,
    title: "RealAH",
    id: "tech-006",
  },
  {
    image: tech7,
    title: "RealAI",
    id: "tech-007",
  },
  {
    image: tech8,
    title: "RealAK",
    id: "tech-008",
  },
  {
    image: tech9,
    title: "RealAJ",
    id: "tech-009",
  },
  {
    image: tech10,
    title: "RealAL",
    id: "tech-010",
  },
  {
    image: tech11,
    title: "RealAM",
    id: "tech-011",
  },
  {
    image: tech12,
    title: "RealAN",
    id: "tech-012",
  },
  {
    image: tech13,
    title: "RealAO",
    id: "tech-013",
  },
  {
    image: tech14,
    title: "RealAP",
    id: "tech-014",
  },
  {
    image: tech15,
    title: "RealAQ",
    id: "tech-015",
  },
];


export const techies = [
  {
    image: techlk1,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
    tag: 'office',
    id: "tech-001",
  },
  {
    image: techlk2,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
    tag: 'party',
    id: "tech-002",
  },
  {
    image: techlk3,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
    tag: 'class',
    id: "tech-003",
  },
  {
    image: techlk4,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
    tag: 'tgif',
    id: "tech-004",
  },
  {
    image: techlk5,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
    tag: 'formal',
    id: "tech-005",
  },
  {
    image: techlk6,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
    id: "tech-006",
  },
  {
    image: techlk7,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
    tag: 'top post',
    id: "tech-007",
  },
  {
    image: techlk8,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
    tag: 't shirt',
    id: "tech-008",
  },
  {
    image: techlk9,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
    tag: 'for all',
    id: "tech-009",
  },
  {
    image: techlk10,
    title: "COLUMBIA",
    subText:
      "Columbia Doverwood crinkle fabric quilted liner jacket in grey Exclusive at ASOS",
    price: "N19,000",
    discount: "60% Off",
    tag: 'formal',
    id: "tech-010",
  },
  {
    image: techlk11,
    title: "ASOS",
    subText: "ASOS DESIGN relaxed wool look overcoat in green",
    price: "N19,000",
    discount: "60% Off",
    tag: 'polo',
    id: "tech-011",
  },
  {
    image: techlk12,
    title: "TOPMAN",
    subText: "Topman faux leather shacket in black",
    price: "N19,000",
    discount: "60% Off",
    tag: 'sleeveless',
    id: "tech-012",
  },
];
