import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";


// initialize an empty api service that we'll inject endpoints into later as needed
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_THEPLUGG_DEV_BASEURL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const { auth } = getState() as RootState;
    const token = auth.token;
    console.log(token);
    
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  return result;
};

export const baseApi = createApi({
  reducerPath: "mainApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Vendors, Customers, Creators", "Product", "User"],
  endpoints: () => ({}),
});
