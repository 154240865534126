import React from "react";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";

interface Props {
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  modalTitle?: string;
  style?: Object;
}

const PuggModal: React.FC<Props> = ({
  modalTitle,
  onClose,
  style,
  children,
}) => {
  return (
    <>
      <ModalContent m="20px" style={style}>
        <ModalHeader
          style={{
            fontFamily: "Geist",
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "26px",
            letterSpacing: "0.2em",
            textAlign: "left",
          }}
        >
          {modalTitle}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </>
  );
};

export default PuggModal;
