import React from "react";

type Props = {};

export const ShareIcon = (props: Props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2488 2.75158L13.674 18.2483H13.6532L9.28639 11.7139L2.75195 7.3471V7.32291L18.2215 2.7233L18.2488 2.75158Z"
        stroke="black"
        strokeLinecap="square"
      />
      <path
        d="M9.49219 11.5571L13.1942 7.85513"
        stroke="black"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const ShareIconBold = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3787_16291)">
        <path
          d="M18.3346 1.66663L9.16797 10.8333"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3346 1.66663L12.5013 18.3333L9.16797 10.8333L1.66797 7.49996L18.3346 1.66663Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3787_16291">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ShareAsLinkIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6718 11.8288L12.3287 6.17192M4.55086 8.29302L3.13664 9.70723C1.57455 11.2693 1.57409 13.8022 3.13619 15.3643C4.69829 16.9264 7.2317 16.9259 8.7938 15.3638L10.2065 13.9498M8.79289 4.05L10.2071 2.63579C11.7692 1.07369 14.3016 1.07397 15.8637 2.63607C17.4258 4.19816 17.4257 6.73085 15.8636 8.29294L14.4501 9.70711"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 4.125H2.75C2.56766 4.125 2.3928 4.19743 2.26386 4.32636C2.13493 4.4553 2.0625 4.63016 2.0625 4.8125V16.5C2.0625 16.8647 2.20737 17.2144 2.46523 17.4723C2.72309 17.7301 3.07283 17.875 3.4375 17.875H18.5625C18.9272 17.875 19.2769 17.7301 19.5348 17.4723C19.7926 17.2144 19.9375 16.8647 19.9375 16.5V4.8125C19.9375 4.63016 19.8651 4.4553 19.7361 4.32636C19.6072 4.19743 19.4323 4.125 19.25 4.125ZM18.5625 16.5H3.4375V6.37656L10.5359 12.882C10.6627 12.998 10.8282 13.0623 11 13.0623C11.1718 13.0623 11.3373 12.998 11.4641 12.882L18.5625 6.37656V16.5Z"
        fill="black"
      />
    </svg>
  );
};

export const EmailIconOutline = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3528 7.13831L9.02965 10.6513L4.70654 7.13831"
        stroke="black"
        strokeWidth="1.125"
        strokeLinecap="square"
      />
      <rect
        x="1.5"
        y="2.75"
        width="15"
        height="13.5"
        stroke="black"
        strokeWidth="1.125"
        strokeLinecap="square"
      />
    </svg>
  );
};
