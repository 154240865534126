import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const clearPersistSlice = createSlice({
  name: "clearPersist",
  initialState,

  reducers: {
    // this will clear everything in the redux state
    clearAll: () => {},
  },
});

export const { clearAll } = clearPersistSlice.actions;

export default clearPersistSlice.reducer;
