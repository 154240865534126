import React from "react";

type Props = {
  type?: string;
  size?: string;
  text?: string;
  className?: string;
};

export const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
};

export const Loaders = ({ type, size, text, className }: Props) => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <span
        className={`${
          className || `loading loading-${type} loading-${size} text-${text}`
        }`}
      ></span>
    </div>
  );
};
