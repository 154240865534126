interface Props {
  fillColor?: string;
  width?: string;
  height?: string;
  color?: string;
}

export const ThumbsdownIcon = ({ fillColor = "white" }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3787_16289)">
        <path
          d="M14.1652 1.66668H16.3902C16.8619 1.65834 17.3201 1.82346 17.6781 2.1307C18.036 2.43793 18.2687 2.8659 18.3319 3.33335V9.16668C18.2687 9.63413 18.036 10.0621 17.6781 10.3693C17.3201 10.6766 16.8619 10.8417 16.3902 10.8334H14.1652M8.33189 12.5V15.8334C8.33189 16.4964 8.59529 17.1323 9.06413 17.6011C9.53297 18.07 10.1689 18.3334 10.8319 18.3334L14.1652 10.8334V1.66668H4.76523C4.36329 1.66214 3.97325 1.80301 3.66697 2.06334C3.3607 2.32368 3.15883 2.68593 3.09856 3.08335L1.94856 10.5833C1.9123 10.8222 1.92842 11.0661 1.99578 11.2981C2.06314 11.5302 2.18014 11.7448 2.33868 11.9271C2.49721 12.1094 2.69349 12.2551 2.91392 12.354C3.13434 12.4529 3.37364 12.5028 3.61523 12.5H8.33189Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3787_16289">
          <rect width="20" height="20" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ThumbsUpIcon = ({ fillColor = "white" }: Props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83073 18.8334H3.33073C2.8887 18.8334 2.46478 18.6578 2.15222 18.3453C1.83966 18.0327 1.66406 17.6088 1.66406 17.1667V11.3334C1.66406 10.8914 1.83966 10.4675 2.15222 10.1549C2.46478 9.84234 2.8887 9.66675 3.33073 9.66675H5.83073M11.6641 8.00008V4.66675C11.6641 4.00371 11.4007 3.36782 10.9318 2.89898C10.463 2.43014 9.8271 2.16675 9.16406 2.16675L5.83073 9.66675V18.8334H15.2307C15.6327 18.838 16.0227 18.6971 16.329 18.4368C16.6353 18.1764 16.8371 17.8142 16.8974 17.4167L18.0474 9.91675C18.0837 9.67788 18.0675 9.43398 18.0002 9.20196C17.9328 8.96993 17.8158 8.75533 17.6573 8.57301C17.4987 8.39069 17.3025 8.24502 17.082 8.1461C16.8616 8.04717 16.6223 7.99735 16.3807 8.00008H11.6641Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_3787_16289">
          <rect width="20" height="20" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
