import React, { useState } from "react";
import { userOutfits } from "../../data";
import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  useBreakpointValue,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { ShareIconBold } from "../../../assets/Icons/ShareIcon";
import ShareModal from "../../Container/ShareModal";
import { OutfitContainer } from "../PageStyles";
import { ProfileStats, UserBio } from "./ProfilePage";
import { useSearchParams, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import InfiniteImageCarousel from "../../Container/InfiniteImageCarousel";
import userPost1 from "../../../assets/images/userOutfit1.png";
import userPost2 from "../../../assets/images/userOutfit2.png";
import { tags } from "../../utils/data";
import PuggButtons from "../../../Components/PuggButtons";
import { ThumbsdownIcon, ThumbsUpIcon } from "../../../assets/Icons/ThumbsIcon";
import styled from "styled-components";
import { ItemNotFound } from "../../../Components/404NotFount";
import { ProductDrawer, ProductModal } from "../../Container/ProductDrawer";

export const Username = styled(Text)`
  font-family: Geist;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #000000;
`;

interface Outfit {
  id: string;
  title: string;
  price?: number;
  tag: string;
  image: string;
  noOfSales: string;
  duration?: number;
}

interface Props {
  outfits: Outfit[];
  defaultDuration?: number;
}

export const mobileImg = [
  { image: userPost1, id: "fit-001", title: "outfit 1" },
  { image: userPost2, id: "fit-002", title: "outfit 2" },
  { image: userPost1, id: "fit-003", title: "outfit 3" },
  { image: userPost2, id: "fit-004", title: "outfit 4" },
];

export const ListView = ({ outfits = [] }: Props) => {
  const display = useBreakpointValue({ base: "mobile", lg: "desktop" });
  const [iconColor, setIconColor] = useState<Record<string, string>>({});
  const [searchParams] = useSearchParams();
  const typeFilter = searchParams.get("tag") || "";

  const {
    isOpen: isShareModalOpen,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();

  const {
    isOpen: isProductModalOpen,
    onOpen: onProductModalOpen,
    onClose: onProductModalClose,
  } = useDisclosure();
  const {
    isOpen: isProductDrawerOpen,
    onOpen: onProductDrawerOpen,
    onClose: onProductDrawerClose,
  } = useDisclosure();

  const handleIconClick = (id: string) => {
    setIconColor((prevColors) => ({
      ...prevColors,
      [id]: prevColors[id] === "#000000" ? "transparent" : "#000000",
    }));
  };

  const displayedOutfits = typeFilter
    ? outfits.filter(
        (outfit) => outfit.tag.toLowerCase() === typeFilter.toLowerCase()
      )
    : outfits;

  // Handle case when no outfits are available
  if (displayedOutfits.length === 0) {
    return <ItemNotFound />;
  }

  return (
    <OutfitContainer style={{ paddingTop: "0px" }}>
      <div className="profile-wrapper">
        {userOutfits.map((user) => (
          <Flex key={user.id} direction="column" pl="20px">
            <Flex direction="column" gap="8px" pb="10px">
              <Flex gap="15px">
                <Image
                  src={user.image}
                  w="37px"
                  h="40px"
                  borderRadius="200px"
                />
                <Flex direction="column" gap="1px">
                  <Username>{user.username}</Username>
                  <UserBio>{user.timePosted} hrs ago</UserBio>
                </Flex>
              </Flex>
              <p style={{ fontSize: "13px" }}>{user.message}</p>
            </Flex>

            {display === "mobile" ? (
              <>
                <Flex
                  // w="100%"
                  overflow="scroll"
                  gap="10px"
                >
                  {displayedOutfits.slice(0, 3).map((product) => (
                    <Image
                      key={product.id}
                      src={product.image}
                      alt={`${product.title} Image`}
                      w="379px"
                      h="382px"
                      borderRadius="9px"
                    />
                  ))}
                </Flex>
              </>
            ) : (
              <>
                <InfiniteImageCarousel
                  images={displayedOutfits.slice(0, 3)}
                  itemHeight={382}
                  itemWidth={379}
                  scrollAmount={200}
                />
              </>
            )}
            <Flex w="100%" align="center" justify="space-between">
              <Flex gap="40px" pt="15px" align="center">
                <Flex gap="6px" align="center" justify="center">
                  <Box onClick={() => handleIconClick("icon1")}>
                    <ThumbsUpIcon fillColor={iconColor["icon1" || "white"]} />
                  </Box>

                  <ProfileStats color="black">981</ProfileStats>
                </Flex>
                <Flex gap="6px" align="center" justify="center">
                  <Box onClick={() => handleIconClick("icon2")}>
                    <ThumbsdownIcon fillColor={iconColor["icon2" || "white"]} />
                  </Box>

                  <ProfileStats color="black">7</ProfileStats>
                </Flex>

                <Flex
                  gap="6px"
                  align="center"
                  justify="center"
                  onClick={onShareModalOpen}
                >
                  <ShareIconBold />
                  <ShareModal
                    isOpen={isShareModalOpen}
                    onClose={onShareModalClose}
                  />
                </Flex>
              </Flex>
              {display === "mobile" ? (
                <>
                  <Text
                    fontFamily="Geist"
                    fontSize="10px"
                    fontWeight={500}
                    lineHeight="26px"
                    letterSpacing="0.2em"
                    textTransform="uppercase"
                    onClick={onProductModalOpen}
                  >
                    view products
                  </Text>
                  <ProductModal
                    isOpen={isProductModalOpen}
                    onClose={onProductModalClose}
                  />
                </>
              ) : (
                <>
                  <Text
                    fontFamily="Geist"
                    fontSize="10px"
                    fontWeight={500}
                    lineHeight="26px"
                    letterSpacing="0.2em"
                    textTransform="uppercase"
                    onClick={onProductDrawerOpen}
                  >
                    view products
                  </Text>
                  <ProductDrawer
                    isOpen={isProductDrawerOpen}
                    onClose={onProductDrawerClose}
                  />
                </>
              )}
            </Flex>
            <Divider w="100%" my="20px" />
          </Flex>
        ))}
      </div>
    </OutfitContainer>
  );
};

export const TileView = ({ outfits = [] }: Props) => {
  const [searchParams] = useSearchParams();
  const typeFilter = searchParams.get("tag") || "";

  const displayedOutfits = typeFilter
    ? outfits.filter((outfit) => {
        const normalizedOutfitTag = outfit.tag.replace("#", "").toLowerCase();

        const matchingTag = tags.find(
          (tag) =>
            tag.name.replace("#", "").toLowerCase() === normalizedOutfitTag
        );

        return (
          matchingTag &&
          normalizedOutfitTag === typeFilter.replace("#", "").toLowerCase()
        );
      })
    : outfits;

  // Handle case when no outfits are available
  if (displayedOutfits.length === 0) {
    return <ItemNotFound />;
  }

  return (
    <Grid
      templateColumns={{
        base: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      gap={{ base: 1, md: 4 }}
      rowGap={{ base: 2, lg: 4 }}
      pl={{ base: "2px", md: "0px" }}
      pb="50px"
    >
      {Array(10)
        .fill(displayedOutfits)
        .flat()
        .map((outfit) => (
          <GridItem
            key={outfit.id}
            bg="white"
            w="100%"
            alignItems="center"
            justifyContent="center"
            pos="relative"
          >
            <Link
              to={`stories/${outfit.title}`}
              state={{
                search: `?${searchParams.toString()}`,
                type: typeFilter,
              }}
            >
              <Image
                src={outfit.image}
                alt={outfit.title}
                w="199px"
                h="201px"
              />
              <Flex
                bg=" #00000096"
                color="white"
                pos="absolute"
                bottom="10px"
                left="50%"
                transform="translateX(-50%)"
              >
                <ProfileStats
                  align="center"
                  justifyContent="center"
                  w="130px"
                  fontSize="10px"
                  px="7px"
                >
                  {outfit.noOfSales}
                </ProfileStats>
              </Flex>
            </Link>
          </GridItem>
        ))}
    </Grid>
  );
};

export const OutfitUI = ({ outfits = [] }: Props) => {
  const display = useBreakpointValue({ base: "mobile", lg: "desktop" });
  const [profileView, setProfileView] = useState<"tiles" | "list">("tiles");
  const [searchParams, setSearchParams] = useSearchParams();

  const typeFilter = searchParams.get("tag") || "";

  const handleFilterChange = (key: string, value: string | null) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (value === null) {
        newParams.delete(key);
      } else {
        newParams.set(key, value);
      }
      return newParams;
    });
  };

  const filterProfileView = () => {
    switch (profileView) {
      case "tiles":
        return <TileView outfits={outfits} />;
      // return <StoryGridCarousel outfits={outfits} />;
      case "list":
        return <ListView outfits={outfits} />;
    }
  };
  return (
    <>
      <Flex
        w="100%"
        overflowX="scroll"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none",
          scrollbarWidth: "none",
        }}
        gap="10px"
        mb={{ base: "0px", lg: "20px" }}
        py="20px"
        pt="20px"
        pl="20px"
        pb={{ base: "20px", lg: "0px" }}
      >
        {tags.map((tag) => (
          <PuggButtons
            key={tag.id}
            id={tag.id}
            text={tag.name}
            btnVariant={typeFilter.includes(tag.name) ? "primary" : "outline"}
            action={() => handleFilterChange("tag", tag.name)}
            radius="10px"
          />
        ))}
        {typeFilter && (
          <PuggButtons
            text="Clear filter"
            btnVariant="outline"
            action={() => handleFilterChange("tag", null)}
          />
        )}
      </Flex>
      {display === "mobile" && (
        <Flex pl="20px">
          {typeFilter && (
            <PuggButtons
              text="Clear filter"
              btnVariant="outline"
              action={() => handleFilterChange("tag", null)}
              radius="10px"
            />
          )}
        </Flex>
      )}

      <Flex direction="column">
        <Flex
          w="100%"
          align="center"
          justify="center"
          color="black"
          gap="30px"
          pb="20px"
        >
          <ProfileStats
            cursor="pointer"
            color={profileView === "tiles" ? "#8548d3" : "black"}
            onClick={() => {
              setProfileView("tiles");
            }}
          >
            tile view
          </ProfileStats>
          <Center height="26px">
            <Divider orientation="vertical" />
          </Center>
          <ProfileStats
            cursor="pointer"
            color={profileView === "list" ? "#8548d3" : "black"}
            onClick={() => {
              setProfileView("list");
            }}
          >
            List view
          </ProfileStats>
        </Flex>
        <Flex w="100%" align="center" justify="center">
          {filterProfileView()}
        </Flex>
      </Flex>
    </>
  );
};

export const StoryGridCarousel: React.FC<Props> = ({
  outfits,
  defaultDuration,
}) => {
  const [searchParams] = useSearchParams();
  const [selectedStory, setSelectedStory] = useState<Outfit | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const typeFilter = searchParams.get("tag") || "";

  const displayedOutfits = typeFilter
    ? outfits.filter(
        (outfit) => outfit.tag.toLowerCase() === typeFilter.toLowerCase()
      )
    : outfits;

  const handleStoryClick = (story: Outfit, index: number) => {
    setSelectedStory(story);
    setCurrentIndex(index);
  };

  const closeStory = () => {
    setSelectedStory(null);
    setCurrentIndex(0);
    setProgress(0);
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === displayedOutfits.length - 1 ? 0 : prevIndex + 1
    );
    setProgress(0);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? displayedOutfits.length - 1 : prevIndex - 1
    );
    setProgress(0);
  };

  if (displayedOutfits.length === 0) {
    return <Text>No outfits available</Text>;
  }

  return (
    <Box position="relative" zIndex={1000}>
      <Grid
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={{ base: 1, md: 4 }}
        rowGap={{ base: 2, lg: 12 }}
        pl={{ base: "2px", md: "0px" }}
        pb="50px"
      >
        {
          // Array(20)
          //   .fill(displayedOutfits)
          //   .flat()
          displayedOutfits.map((outfit, index) => (
            <GridItem
              key={outfit.id}
              bg="white"
              w="100%"
              alignItems="center"
              justifyContent="center"
              onClick={() => handleStoryClick(outfit, index)}
              pos="relative"
              cursor="pointer"
            >
              <Image src={outfit.image} alt={outfit.title} />
              <Flex
                bg="#00000096"
                color="white"
                pos="absolute"
                bottom="10px"
                left="50%"
                transform="translateX(-50%)"
              >
                <Text fontSize="10px" px="7px">
                  {outfit.noOfSales}
                </Text>
              </Flex>
            </GridItem>
          ))
        }
      </Grid>

      <AnimatePresence>
        {selectedStory && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <Box
              position="absolute"
              top="20px"
              right="15px"
              zIndex={1001}
              onClick={closeStory}
              cursor="pointer"
            >
              <X />
            </Box>
            <Box
              position="absolute"
              top="20px"
              left="50px"
              color="white"
              zIndex={1001}
            >
              <Text>{displayedOutfits[currentIndex].title}</Text>
            </Box>
            <Box
              position="absolute"
              top="70px"
              left="10px"
              right="10px"
              zIndex={1001}
            >
              <Flex gap="5px">
                {displayedOutfits.map((_, index) => (
                  <Box
                    key={index}
                    flex={1}
                    height="3px"
                    backgroundColor="rgba(255, 255, 255, 0.5)"
                    position="relative"
                  >
                    <Box
                      position="absolute"
                      left={0}
                      top={0}
                      height="100%"
                      width={
                        index === currentIndex
                          ? `${progress}%`
                          : index < currentIndex
                          ? "100%"
                          : "0%"
                      }
                      backgroundColor="white"
                      transition="width 0.1s linear"
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
            <Image
              src={displayedOutfits[currentIndex].image}
              alt={displayedOutfits[currentIndex].title}
              objectFit="cover"
              width="100%"
              height="100%"
            />
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              color="red"
            >
              <Box flex={1} onClick={goToPrevious} cursor="pointer" />
              <Box flex={1} onClick={goToNext} cursor="pointer" />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};
