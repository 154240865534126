export const EditIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4042_17812)">
        <path
          d="M13.8817 1.11739C13.4851 0.721425 12.9476 0.499023 12.3872 0.499023C11.8268 0.499023 11.2892 0.721425 10.8927 1.11739L0.5 11.5101V14.4991H3.489L13.8817 4.10639C14.2775 3.70974 14.4998 3.17226 14.4998 2.61189C14.4998 2.05153 14.2775 1.51405 13.8817 1.11739ZM3.00833 13.3324H1.66667V11.9907L9.43083 4.23239L10.7725 5.57406L3.00833 13.3324ZM13.0568 3.28156L11.5944 4.74398L10.2557 3.40231L11.7175 1.94223C11.8954 1.76431 12.1367 1.66436 12.3883 1.66436C12.6399 1.66436 12.8813 1.76431 13.0592 1.94223C13.2371 2.12014 13.337 2.36145 13.337 2.61306C13.337 2.86467 13.2371 3.10598 13.0592 3.28389L13.0568 3.28156Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4042_17812">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const WhiteEditIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4050_19964)">
        <path
          d="M13.3817 1.11758C12.9851 0.721608 12.4476 0.499207 11.8872 0.499207C11.3268 0.499207 10.7892 0.721608 10.3927 1.11758L0 11.5102V14.4992H2.989L13.3817 4.10658C13.7775 3.70992 13.9998 3.17244 13.9998 2.61208C13.9998 2.05171 13.7775 1.51423 13.3817 1.11758ZM2.50833 13.3326H1.16667V11.9909L8.93083 4.23258L10.2725 5.57424L2.50833 13.3326ZM12.5568 3.28174L11.0944 4.74416L9.75567 3.40249L11.2175 1.94241C11.3954 1.76449 11.6367 1.66454 11.8883 1.66454C12.1399 1.66454 12.3813 1.76449 12.5592 1.94241C12.7371 2.12033 12.837 2.36163 12.837 2.61324C12.837 2.86485 12.7371 3.10616 12.5592 3.28408L12.5568 3.28174Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4050_19964">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
