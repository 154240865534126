import sizeSM from "../../assets/images/MM-sm.png";
import sizeMD from "../../assets/images/MM-md.png";
import sizeL from "../../assets/images/MM-l.png";
import sizeXL from "../../assets/images/MM-xl.png";
import model from "../../assets/images/LPProd2.png";
import fit1 from "../../assets/images/Group 19.png";
import fit2 from "../../assets/images/Group 20.png";
import fit3 from "../../assets/images/Group 21.png";
import fit4 from "../../assets/images/Group 22.png";
import fit5 from "../../assets/images/Group 23.png";
import fit6 from "../../assets/images/Group 24.png";
import fit7 from "../../assets/images/image 1024.png";
import fit8 from "../../assets/images/image 1031.png";
import userPost1 from '../../assets/images/userOutfit1.png'
import userPost2 from '../../assets/images/userOutfit2.png'


export const tags = [
  { id: "tag-001", name: "Top Posts" },
  { id: "tag-002", name: "Most Recent" },
  { id: "tag-003", name: "#tgif" },
  { id: "tag-004", name: "#formal" },
  { id: "tag-005", name: "#office" },
  { id: "tag-006", name: "#party" },
  { id: "tag-007", name: "#date" },
  { id: "tag-008", name: "#school" },
  { id: "tag-009", name: "#owambe" },
  { id: "tag-010", name: "casual" },
  { id: "tag-011", name: "sleeveless" },
  { id: "tag-012", name: "polo" },
  { id: "tag-013", name: "t shirt" },
];

export const womenSizes = [
  { title: "women1", image: model },
  { title: "women2", image: model },
  { title: "women3", image: model },
  { title: "women4", image: model },
  { title: "women5", image: fit1 },
  { title: "women6", image: fit2 },
  { title: "women7", image: fit3 },
  { title: "women8", image: fit4 },
];

export const menSizes = [
  { title: "fit1", image: fit1 },
  { title: "fit2", image: fit2 },
  { title: "fit3", image: fit3 },
  { title: "fit4", image: fit4 },
  { title: "fit5", image: fit5 },
  { title: "fit6", image: fit6 },
  { title: "fit7", image: fit7 },
  { title: "fit8", image: fit8 },
];

export const kidsSizes = [
  { title: "kids1", image: fit5 },
  { title: "kids2", image: fit6 },
  { title: "kids3", image: fit7 },
  { title: "kid4", image: fit8 },
  { title: "kids5", image: sizeSM },
  { title: "kids6", image: sizeMD },
  { title: "kids7", image: sizeL },
  { title: "kids8", image: sizeXL },
];

export const colors = [
  { name: "red", colorCode: " #CA0000" },
  { name: "yellow", colorCode: " #FFD600" },
  { name: "blue", colorCode: " #0057FF" },
  { name: "pueple", colorCode: " #BD00FF" },
  { name: "black", colorCode: " #000000" },
  { name: "green", colorCode: " #60BF00" },
  { name: "pink", colorCode: " #FF0099" },
  { name: "light-blue", colorCode: " #00D1FF" },
];

export const customerOrderColumns = [
  { key: "item", title: "Item" },
  { key: "quantity", title: "Quantity" },
  { key: "price", title: "Price" },
];

export const customerOrderData = [
  {
    id: 1,
    avatar:
      "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    item: "NIKE Air 2000",
    quantity: 1,
    price: "125,000.00",
  },
  {
    id: 1,
    avatar:
      "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    item: "Black ASOS shirt",
    quantity: 1,
    price: "125,000.00",
  },
  {
    id: 3,
    avatar:
      "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    item: "Shein summer heels",
    quantity: 1,
    price: "125,000.00",
  },
];


export const userPosts = [
  {id: 'userPst-001', image: userPost1, title: "Waliyah's closet", tag: 'top posts', noOfSales: 'view products'},
  {id: 'userPst-002', image: userPost2, title: "Waliyah's wardrobe", tag: 'most recent', noOfSales: 'view products'},
  {id: 'userPst-003', image: userPost1, title: "Waliyah's closet", tag: 'tgif', noOfSales: 'view products'},
  {id: 'userPst-004', image: userPost2, title: "Waliyah's wardrobe", tag: 'formal', noOfSales: 'view products'},
  {id: 'userPst-020', image: userPost1, title: "Waliyah's closet", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-005', image: userPost2, title: "Waliyah's wardrobe", tag: 'party', noOfSales: 'view products'},
  {id: 'userPst-006', image: userPost1, title: "Waliyah's closet", tag: 'date', noOfSales: 'view products'},
  {id: 'userPst-007', image: userPost2, title: "Waliyah's wardrobe", tag: 'school', noOfSales: 'view products'},
  {id: 'userPst-008', image: userPost1, title: "Waliyah's closet", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-009', image: userPost2, title: "Waliyah's wardrobe", tag: 'owanbe', noOfSales: 'view products'},
  {id: 'userPst-010', image: userPost1, title: "Waliyah's closet", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-011', image: userPost2, title: "Waliyah's wardrobe", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-012', image: userPost2, title: "Waliyah's closet", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-013', image: userPost1, title: "Waliyah's wardrobe", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-014', image: userPost2, title: "Waliyah's closet", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-015', image: userPost2, title: "Waliyah's wardrobe", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-016', image: userPost1, title: "Waliyah's closet", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-017', image: userPost2, title: "Waliyah's wardrobe", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-018', image: userPost1, title: "Waliyah's closet", tag: 'office', noOfSales: 'view products'},
  {id: 'userPst-019', image: userPost2, title: "Waliyah's wardrobe", tag: 'office', noOfSales: 'view products'},
]
