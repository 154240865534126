import React from "react";

interface Props {
  color?: string;
  fillColor?: string;
  width?: string;
  height?: string;
}

const CartIcon = ({
  fillColor = "transparent",
  color,
  width,
  height,
}: Props) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.486 6.75H5.33601C5.15147 6.75087 4.97355 6.81891 4.83552 6.94141C4.69749 7.06391 4.60879 7.23249 4.58601 7.41562L3.25476 19.4156C3.24292 19.5202 3.25322 19.6261 3.28497 19.7264C3.31673 19.8267 3.36924 19.9192 3.4391 19.9979C3.50895 20.0766 3.59458 20.1397 3.69042 20.1831C3.78626 20.2266 3.89017 20.2494 3.99539 20.25H21.8266C21.9319 20.2494 22.0358 20.2266 22.1316 20.1831C22.2274 20.1397 22.3131 20.0766 22.3829 19.9979C22.4528 19.9192 22.5053 19.8267 22.5371 19.7264C22.5688 19.6261 22.5791 19.5202 22.5673 19.4156L21.236 7.41562C21.2132 7.23249 21.1245 7.06391 20.9865 6.94141C20.8485 6.81891 20.6706 6.75087 20.486 6.75Z"
        fill={fillColor}
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16113 9.75V6.75C9.16113 5.75544 9.55622 4.80161 10.2595 4.09835C10.9627 3.39509 11.9166 3 12.9111 3C13.9057 3 14.8595 3.39509 15.5628 4.09835C16.266 4.80161 16.6611 5.75544 16.6611 6.75V9.75"
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MobileCartIcon = ({
  fillColor = "transparent",
  color,
  width,
  height,
}: Props) => {
  return (
    <svg
      width={width || "18"}
      height={height || "17"}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.486 6.75H5.33601C5.15147 6.75087 4.97355 6.81891 4.83552 6.94141C4.69749 7.06391 4.60879 7.23249 4.58601 7.41562L3.25476 19.4156C3.24292 19.5202 3.25322 19.6261 3.28497 19.7264C3.31673 19.8267 3.36924 19.9192 3.4391 19.9979C3.50895 20.0766 3.59458 20.1397 3.69042 20.1831C3.78626 20.2266 3.89017 20.2494 3.99539 20.25H21.8266C21.9319 20.2494 22.0358 20.2266 22.1316 20.1831C22.2274 20.1397 22.3131 20.0766 22.3829 19.9979C22.4528 19.9192 22.5053 19.8267 22.5371 19.7264C22.5688 19.6261 22.5791 19.5202 22.5673 19.4156L21.236 7.41562C21.2132 7.23249 21.1245 7.06391 20.9865 6.94141C20.8485 6.81891 20.6706 6.75087 20.486 6.75Z"
        fill={fillColor}
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16113 9.75V6.75C9.16113 5.75544 9.55622 4.80161 10.2595 4.09835C10.9627 3.39509 11.9166 3 12.9111 3C13.9057 3 14.8595 3.39509 15.5628 4.09835C16.266 4.80161 16.6611 5.75544 16.6611 6.75V9.75"
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CartIcon;
